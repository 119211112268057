<div style="padding: 5px">
  <nz-breadcrumb style="margin: 10px">
    <nz-breadcrumb-item>
      <i nz-icon nzType="home"></i>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a routerLink="/psst/dashboard">
        <span>Mantenimiento y Configuración</span>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>Roles</nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-card [nzBordered]="false" nzTitle="Lista de Roles">
    <div class="p-shadow-1">
      <p-table #dtPerfiles [value]="lstRoles" styleClass="p-datatable-sm"
               dataKey="perfil"
               [rows]="5"
               [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[5,10,25,50]"
               [loading]="loading"
               [paginator]="true"
               currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
               [globalFilterFields]="['descripcionRol','fechaCreacion','estado']">
        <ng-template pTemplate="caption">
          <div class="flex">
            <button button mat-stroked-button color="accent" class="p-mr-2 p-mb-2" [matTooltip]="'Nuevo Rol'"
                    (click)="operacion('pRegistrarRol', 'ADD_ROL')">
              <mat-icon color="primary">note_add</mat-icon>
              Agregar
            </button>
            <span class="p-input-icon-left ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dtPerfiles.filterGlobal($any($event.target).value, 'contains')"
                     placeholder="Buscar..."/>
            </span>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th>Nombre</th>
            <th>Fecha Creación</th>
            <th>Estado</th>
            <th style="width:6rem">Acción</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rol>
          <tr [pSelectableRow]="rol">
            <td>{{rol.descripcionRol}}</td>
            <td>{{rol.fechaCreacion}}</td>
            <td>{{rol.estado}}</td>
            <td style="text-align:center">
              <button *ngIf="rol.estado == 'ACTIVO'" pButton pRipple type="button" icon="pi pi-pencil"
                      class="p-button-rounded p-button-text p-button-success"
                      nz-popconfirm nzPopconfirmTitle="EDITAR rol seleccionado?"
                      (nzOnConfirm)="pRegistrarRol('EDIT_ROL',rol)" nzOkText="Aceptar" nzCancelText="Cancelar"
                      [matTooltip]="'Editar rol'"></button>
              <button *ngIf="rol.estado == 'ACTIVO'" pButton pRipple type="button" icon="pi pi-ban"
                      class="p-button-rounded p-button-text p-button-danger"
                      nz-popconfirm nzPopconfirmTitle="Eliminar rol seleccionado?"
                      (nzOnConfirm)="gestionDocumento(rol)" nzOkText="Aceptar" nzCancelText="Cancelar"
                      [matTooltip]="'Eliminar rol'"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </nz-card>
</div>


