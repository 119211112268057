<mat-toolbar color="primary">
  <span>{{title}}</span>
  <span class="toolbar-spacer-custom"></span>
  <button mat-icon-button title="Cerrar">
    <mat-icon aria-label="Cancelar" (click)="onNoClick({correcto: true, notificacion: ''})">close</mat-icon>
  </button>
</mat-toolbar>
<div mat-dialog-content class="mat-typography">
  <form [formGroup]="fgObtDatosPass">
    <div class="p-grid">
      <span>La nueva contraseña debe contener como mínimo 7 caracteres.</span>
      <mat-form-field class="p-col">
        <mat-label>Contraseña anterior:</mat-label>
        <input type="password" matInput formControlName="passwordActual" placeholder="Escriba su antigua contraseña" required>
      </mat-form-field>
      <mat-form-field class="p-col">
        <mat-label>Contraseña nueva:</mat-label>
        <input type="password" matInput formControlName="passwordNuevo1" placeholder="Escriba su nueva contraseña" required>
      </mat-form-field>
      <mat-form-field class="p-col">
        <mat-label>Reescribir nueva contraseña:</mat-label>
        <input type="password" matInput formControlName="passwordNuevo2" placeholder="Reescriba su nueva contraseña" required>
      </mat-form-field>
    </div>
  </form>
  <mat-card-actions align="end">
    <button mat-flat-button color="accent" [disabled]="fgObtDatosPass.invalid" (click)="cambiarPass()">
      Guardar
    </button>
  </mat-card-actions>
</div>
<p-toast></p-toast>
