<div class="modal-theme">
  <mat-toolbar color="primary">
    <span>{{title}}</span>
    <span class="toolbar-spacer-custom"></span>
    <a>
      <i nz-icon nzType="close" nzTheme="outline" (click)="onNoClick({correcto: false})" style="color:silver"></i>
    </a>
  </mat-toolbar>
  <div mat-dialog-content>
    <mat-card class="content-tab mat-elevation-z0">
      <mat-card-content>
        <form nz-form [nzLayout]="'vertical'" [formGroup]="fgPerfil">
          <nz-form-item>
            <nz-form-label>Nombre</nz-form-label>
            <nz-form-control nzErrorTip="Ingrese el nombre del Perfil!">
              <input nz-input formControlName="perfil" placeholder="Ingrese el nombre del Perfil" *ngIf="!isEdit"/>
              <strong *ngIf="isEdit">{{valPerfil}}</strong>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label>Descripción</nz-form-label>
            <nz-form-control nzErrorTip="Ingrese la desripción del Perfil!">
              <input nz-input formControlName="descripcionPerfil" placeholder="Ingrese la desripción del Perfil"/>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label>Roles Asignados</nz-form-label>
            <nz-form-control nzErrorTip="Asigne al menos un Rol!">
              <nz-select formControlName="roles" nzShowSearch nzAllowClear
                         nzPlaceHolder="Roles"
                         nzMode="multiple"
                         nzSize="default"
                         [(ngModel)]="listOfSelectedValue">
                <nz-option *ngFor="let option of lstRoles" [nzLabel]="option.descripcionRol"
                           [nzValue]="option.rol"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-divider></mat-divider>
  <div class="flex justify-content-end col-12">
    <button nz-button nzType="default" (click)="onNoClick({correcto: false})">
      <span>Cancelar</span>
    </button>&nbsp;
    <button nz-button nzType="primary" (click)="gestionWorkflow()">
      <span>Aceptar</span>
    </button>
  </div>
</div>
