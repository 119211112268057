<div>
  <nz-steps [nzCurrent]="current" nzSize="small">
    <nz-step
      *ngFor="let step of this.steps"
      [nzTitle]="step.title"
      [nzDescription]="step.description"
      [nzPercentage]="step.async ? step.percentage : null"
    ></nz-step>
  </nz-steps>
  <div class="steps-content">
    <div *ngIf="current===0">
      <form [formGroup]="fgDatosGrals">
        <div class="grid">
          <mat-form-field class="col">
            <mat-label>Nombres y apellidos:</mat-label>
            <input matInput formControlName="nombreCompleto" type="text">
          </mat-form-field>
          <mat-form-field class="col">
            <mat-label>Cargo:</mat-label>
            <input matInput formControlName="cargo" type="text">
          </mat-form-field>
        </div>
        <div class="p-fluid grid formgrid">
          <div class="field col">
            <label for="selectTipoViaje" class="text-sm">Tipo de Viaje:</label>
            <div id="selectTipoViaje">
              <nz-form-control>
                <nz-select formControlName="estipendioId" nzShowSearch nzAllowClear
                           nzPlaceHolder="Seleccione una opción..."
                           (ngModelChange)="prcOptDestinosNivel($event)"
                           nzSize="default" [nzDisabled]="isDescargo">
                  <nz-option *ngFor="let opt of optEstipendios"
                             [nzLabel]="opt.estMoneda +'.'+ opt.estCosto +' | '+ opt.destino  +' | '+  opt.estLugar"
                             [nzValue]="opt.idEstipendio"></nz-option>
                </nz-select>
              </nz-form-control>
            </div>
          </div>
        </div>
        <div class="p-fluid grid formgrid">
          <div class="field col">
            <label for="selectDestino" class="text-sm">Destino:</label>
            <div id="selectDestino">
              <nz-form-control>
                <nz-select formControlName="destinoId" nzShowSearch nzAllowClear
                           nzPlaceHolder="Seleccione una opción..."
                           nzSize="default" [nzDisabled]="isDescargo">
                  <nz-option *ngFor="let opt of optDestinos" [nzLabel]="opt.descripcion"
                             [nzValue]="opt.idDestino"></nz-option>
                </nz-select>
              </nz-form-control>
            </div>
          </div>
        </div>
        <div class="grid" *ngIf="isTipoViajeExt">
          <div class="field col">
            <label for="lblTipoCambio" class="text-sm">Tipo de cambio:</label>
            <div id="lblTipoCambio">
              <nz-form-control>
                <nz-input-number
                  formControlName="solTipoCambio"
                  [nzMin]="0"
                  [nzDisabled]="isDescargo"
                ></nz-input-number>
              </nz-form-control>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="field col">
            <label for="objViaje" class="text-sm">Objetivo del viaje:</label>
            <div id="objViaje">
              <nz-form-control>
                <textarea nz-input placeholder="Objetivo del viaje..." [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                  formControlName="solObjetivo" [readOnly]="isDescargo"></textarea>
              </nz-form-control>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col">
            <nz-form-item>
              <nz-form-label [nzSpan]="null">Viaje de fin de semana o feriado?</nz-form-label>
              <nz-form-control [nzSpan]="null">
                <nz-radio-group formControlName="solEsFeriado" [nzDisabled]="isDescargo">
                  <label nz-radio [nzValue]="false" (click)="isFinSemanaFeriado = false; onchangeFinSemFeriado(isFinSemanaFeriado)">No</label>
                  <label nz-radio [nzValue]="true" (click)="isFinSemanaFeriado = true; onchangeFinSemFeriado(isFinSemanaFeriado)">Si</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="grid" *ngIf="isFinSemanaFeriado">
          <div class="field col">
            <label for="lblJustificacionFeriado" class="text-sm">Aclaración / justificación de viaje de fin de semana o feriado:</label>
            <div id="lblJustificacionFeriado">
              <nz-form-control>
                <textarea nz-input placeholder="Aclaración / justificación de viaje de fin de semana o feriado..." [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                          formControlName="solJustificacionFeriado" [readOnly]="isDescargo"
                ></textarea>
              </nz-form-control>
            </div>
          </div>

        </div>
        <div class="grid" *ngIf="isFinSemanaFeriado">
          <div class="col">
            <label nz-checkbox formControlName="solRmFinsemanaFeriado" [nzDisabled]="isDescargo">R.M. Fin de Semana o Feriado</label>
          </div>
          <div class="col">
            <label nz-checkbox formControlName="solRmReembolso" [nzDisabled]="isDescargo">R.M. Reembolso</label>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="current===1">
      <form [formGroup]="fgDatosGrals">
        <h4>Declaración en Comisión o Viaje:</h4>
        <div class="grid">
          <div class="col">
            <nz-form-control>
              <nz-select formControlName="solUsuarioDeclara" nzShowSearch nzAllowClear
                         nzPlaceHolder="Quién declara en comisión o viaje"
                         nzSize="default" [nzDisabled]="isDescargo">
                <nz-option *ngFor="let opt of optPersonal" [nzLabel]="opt.nombreCompleto+' | '+opt.puesto"
                           [nzValue]="opt.idUsuario"></nz-option>
              </nz-select>
            </nz-form-control>
          </div>
        </div>
        <div class="grid">
          <nz-form-item class="col">
            <nz-form-control>
              <nz-select formControlName="solLugarDeclara" nzShowSearch nzAllowClear
                         nzPlaceHolder="Lugar declaración en comisión o viaje"
                         nzSize="default" [nzDisabled]="isDescargo">
                <nz-option *ngFor="let opt of optDeptos"
                           [nzLabel]="opt.nombre"
                           [nzValue]="opt.idClasificador"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <div class="col">
            <nz-date-picker
              nzFormat="dd/MM/yyyy"
              nzPlaceHolder="Fecha de declaración"
              formControlName="solFechaDeclara"
              [nzDisabled]="isDisabled"
            ></nz-date-picker>
          </div>
        </div>
        <br>
        <h4>Autorización del Viaje:</h4>
        <div class="grid">
          <div class="col">
            <nz-form-control>
              <nz-select formControlName="solUsuarioAutoriza" nzShowSearch nzAllowClear
                         nzPlaceHolder="Quién autoriza el viaje"
                         nzSize="default" [nzDisabled]="isDescargo">
                <nz-option *ngFor="let opt of optPersonal" [nzLabel]="opt.nombreCompleto+' | '+opt.puesto"
                           [nzValue]="opt.idUsuario"></nz-option>
              </nz-select>
            </nz-form-control>
          </div>
        </div>
        <div class="grid">
          <nz-form-item class="col">
            <nz-form-control>
              <nz-select formControlName="solLugarAutoriza" nzShowSearch nzAllowClear
                         nzPlaceHolder="Lugar autorización del viaje"
                         nzSize="default" [nzDisabled]="isDescargo">
                <nz-option *ngFor="let opt of optDeptos"
                           [nzLabel]="opt.nombre"
                           [nzValue]="opt.idClasificador"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <div class="col">
            <nz-date-picker
              nzFormat="dd/MM/yyyy"
              nzPlaceHolder="Fecha de autorización"
              formControlName="solFechaAutoriza"
              [nzDisabled]="isDisabled"
            ></nz-date-picker>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="current===2">
      <form [formGroup]="fgDatosGrals">
        <div class="grid">
          <div class="field col">
            <label for="lblRutaAereo" class="text-sm">Pasajes aéreos en la ruta (especificar):</label>
            <div id="lblRutaAereo">
              <nz-form-control>
                <textarea nz-input placeholder="Pasajes aéreos en la ruta (especificar)..." [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                          formControlName="pvRutaAereo"
                ></textarea>
              </nz-form-control>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="field col">
            <label for="lblNroPsj" class="text-sm">Número de Pasajes Aéreos</label>
            <div id="lblNroPsj">
              <nz-form-control>
                <nz-input-number
                  formControlName="pvNumeroPasajes"
                  [nzMin]="0"
                  [nzMax]="100"
                  [nzStep]="1"
                  [nzPlaceHolder]="'Número de Pasajes Aéreos'"
                ></nz-input-number>
              </nz-form-control>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="field col">
            <label for="lblRutaTerrestre" class="text-sm">Pasajes terrestres en la ruta (especificar):</label>
            <div id="lblRutaTerrestre">
              <nz-form-control>
                <textarea nz-input placeholder="Pasajes terrestres en la ruta (especificar)..." [nzAutosize]="{ minRows: 2, maxRows: 6 }"
                          formControlName="pvRutaTerrestre"
                ></textarea>
              </nz-form-control>
            </div>
          </div>
        </div>
        <div class="p-fluid grid formgrid">
          <div class="field col">
            <label for="rPicker" class="text-sm">Fecha y hora previstas Salida/Retorno</label>
            <div id="rPicker">
              <nz-form-control>
                <nz-range-picker
                  nzShowTime
                  [nzShowTime]="{ nzFormat: 'HH:mm' }"
                  nzFormat="dd/MM/yyyy HH:mm"
                  formControlName="pvFechaHoraSalidaRetorno"
                  (ngModelChange)="onChange($event)"
                ></nz-range-picker>
              </nz-form-control>
            </div>
          </div>
          <div class="field col">
            <label for="lblDias" class="text-sm">Número de Días</label>
            <div id="lblDias">
                <nz-input-number
                  formControlName="pvNumeroDias"
                  [nzMin]="0"
                  [nzMax]="100"
                  [nzStep]="0.25"
                  (ngModelChange) = "onChangeDiasViaje($event)"
                  [nzPlaceHolder]="'Número de Días'"
                  [nzReadOnly]="true"
                ></nz-input-number>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col">
            <nz-space nzSize="small">
              <div class="field" *nzSpaceItem>
                <label for="lblTotalAereo" class="text-sm">Total pasajes aéreos</label>
                <div id="lblTotalAereo">
                  <nz-form-control>
                    <nz-input-number
                      formControlName="pvImporteAereo"
                      [nzMin]="0"
                      [nzMax]="100000"
                      [nzStep]="0.5"
                      [nzFormatter]="formatterBol"
                      [nzParser]="parserBol"
                      [nzPlaceHolder]="'Sub total importes pasajes aéreos'"
                      (ngModelChange) = "onChangeTotalPv($event)"
                      style="width: 70%"
                    ></nz-input-number>
                  </nz-form-control>
                </div>
              </div>
              <div class="field" *nzSpaceItem>
                <label for="lblTotalTerr" class="text-sm">Total pasajes terrestres</label>
                <div id="lblTotalTerr">
                  <nz-form-control>
                    <nz-input-number
                      formControlName="pvImporteTerrestre"
                      [nzMin]="0"
                      [nzMax]="100000"
                      [nzFormatter]="formatterBol"
                      [nzParser]="parserBol"
                      [nzPlaceHolder]="'Sub total importes pasajes terrestres'"
                      (ngModelChange) = "onChangeTotalPv($event)"
                      style="width: 70%"
                    ></nz-input-number>
                  </nz-form-control>
                </div>
              </div>
              <div class="field" *nzSpaceItem>
                <label for="lblTotalPsj" class="text-sm">Total pasajes</label>
                <div id="lblTotalPsj">
                  <nz-form-control>
                    <nz-input-number
                      formControlName="pvImporteTotal"
                      [nzMin]="0"
                      [nzMax]="100000"
                      [nzFormatter]="formatterBol"
                      [nzParser]="parserBol"
                      [nzPlaceHolder]="'Total pasajes'"
                      [nzDisabled]="true"
                      style="width: 70%"
                    ></nz-input-number>
                  </nz-form-control>
                </div>
              </div>
            </nz-space>
          </div>
        </div>
        <div class="grid">
          <div class="col">
            <nz-form-item>
              <nz-form-label [nzSpan]="null">Renuncia a Viático de Fin de Semana o Feriado?</nz-form-label>
              <nz-form-control [nzSpan]="null">
                <nz-radio-group formControlName="pvRenunciaViatico">
                  <label nz-radio [nzValue]="false" (click)="isRenunciaViatico = false; onchangeDiasRenunciaViatico(isRenunciaViatico)">No</label>
                  <label nz-radio [nzValue]="true" (click)="isRenunciaViatico = true; onchangeDiasRenunciaViatico(isRenunciaViatico)">Si</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="p-fluid grid formgrid" *ngIf="isRenunciaViatico">
          <div class="field col">
            <label for="multiple" class="text-sm">Día(s) a los cuales renuncia</label>
            <p-calendar formControlName="pvDiasRenunciaViatico" selectionMode="multiple" [readonlyInput]="true"
                        inputId="multiple" dateFormat="dd/mm/yy" [defaultDate]="dflDay" [minDate]="minDate" [maxDate]="maxDate"></p-calendar>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="current===3">
      <form [formGroup]="fgDatosGrals">
        <div class="grid">
          <div class="col">
            <nz-form-label>Pago 100% (Viático completo)</nz-form-label>
          </div>
          <div class="col">
            <nz-space nzSize="large">
              <nz-form-control *nzSpaceItem>
              <nz-input-number
                formControlName="pv100Dias"
                [nzMin]="0"
                [nzMax]="maxDias"
                [nzStep]="0.25"
                [nzFormatter]="formatterDias"
                [nzParser]="parserDias"
                [nzPlaceHolder]="'día'"
                (ngModelChange) = "onChangeViaticos($event, 100)"
              ></nz-input-number>&nbsp;
              </nz-form-control>
              <nz-input-number
                *nzSpaceItem
                formControlName="pv100Importe"
                [nzMin]="0"
                [nzMax]="100000"
                [nzFormatter]="formatterBol"
                [nzParser]="parserBol"
                [nzDisabled]="true"
                (ngModelChange) = "onChangeTotalImpVco($event)"
                style="width: 110px"
              ></nz-input-number>
            </nz-space>
          </div>
        </div>
        <div class="grid">
          <div class="col">
            <nz-form-label>Pago 70% (Hospedaje pagado)</nz-form-label>
          </div>
          <div class="col">
            <nz-space nzSize="large">
              <nz-form-control *nzSpaceItem>
                <nz-input-number
                  formControlName="pv70Dias"
                  [nzMin]="0"
                  [nzMax]="maxDias"
                  [nzStep]="0.25"
                  [nzFormatter]="formatterDias"
                  [nzParser]="parserDias"
                  [nzPlaceHolder]="'día'"
                  (ngModelChange) = "onChangeViaticos($event, 70)"
                ></nz-input-number>&nbsp;
              </nz-form-control>
              <nz-input-number
                *nzSpaceItem
                formControlName="pv70Importe"
                [nzMin]="0"
                [nzMax]="100000"
                [nzFormatter]="formatterBol"
                [nzParser]="parserBol"
                [nzDisabled]="true"
                (ngModelChange) = "onChangeTotalImpVco($event)"
                style="width: 110px"
              ></nz-input-number>
            </nz-space>
          </div>
        </div>
        <div class="grid">
          <div class="col">
            <nz-form-label>Pago 25% (Alimentación y hospedaje pagados)</nz-form-label>
          </div>
          <div class="col">
            <nz-space nzSize="large">
              <nz-form-control *nzSpaceItem>
                <nz-input-number
                  formControlName="pv25Dias"
                  [nzMin]="0"
                  [nzMax]="maxDias"
                  [nzStep]="0.25"
                  [nzFormatter]="formatterDias"
                  [nzParser]="parserDias"
                  [nzPlaceHolder]="'día'"
                  (ngModelChange) = "onChangeViaticos($event, 25)"
                ></nz-input-number>&nbsp;
              </nz-form-control>
              <nz-input-number
                *nzSpaceItem
                formControlName="pv25Importe"
                [nzMin]="0"
                [nzMax]="100000"
                [nzFormatter]="formatterBol"
                [nzParser]="parserBol"
                [nzDisabled]="true"
                (ngModelChange) = "onChangeTotalImpVco($event)"
                style="width: 110px"
              ></nz-input-number>
            </nz-space>
          </div>
        </div>
        <div class="grid" *ngIf="isExterno">
          <div class="col">
            <nz-form-label>Descuento del 13% RC IVA?</nz-form-label>
          </div>
          <div class="col">
            <nz-space nzSize="small">
                <nz-radio-group formControlName="pvRcIva" (ngModelChange) = "onChangeRcIva($event)" *nzSpaceItem  [nzDisabled]="isDisabledRcIva">
                  <label nz-radio [nzValue]="false">No</label>
                  <label nz-radio [nzValue]="true">Si</label>
                </nz-radio-group>
              <nz-input-number
                *nzSpaceItem
                formControlName="pvRcIvaImporte"
                [nzMin]="0"
                [nzMax]="100000"
                [nzFormatter]="formatterBol"
                [nzParser]="parserBol"
                [nzDisabled]="true"
                style="width: 110px"
              ></nz-input-number>
            </nz-space>
          </div>
        </div>
        <div class="grid">
          <div class="col">
            <nz-form-label>Total viáticos</nz-form-label>
          </div>
          <div class="col">
              <nz-input-number
                formControlName="pvPasViaTotal"
                [nzMin]="0"
                [nzMax]="100000"
                [nzFormatter]="formatterBol"
                [nzParser]="parserBol"
                [nzDisabled]="true"
                style="width: 110px"
              ></nz-input-number>
          </div>
        </div>
        <div *ngIf="isTipoViajeExt && isGastosRep">
          <nz-divider nzText="Cálculo de Gastos de Representación" nzOrientation="left"></nz-divider>
          <div class="grid">
            <div class="col">
              <nz-form-label>Gastos de representación (25% viáticos)</nz-form-label>
            </div>
            <div class="col">
              <nz-space nzSize="small">
                <nz-radio-group formControlName="pvGastosRepre" (ngModelChange) = "onChangeGstRpr($event)" *nzSpaceItem>
                  <label nz-radio [nzValue]="false">No</label>
                  <label nz-radio [nzValue]="true">Si</label>
                </nz-radio-group>
                <nz-input-number
                  *nzSpaceItem
                  formControlName="pvGastosRepreImporte"
                  [nzMin]="0"
                  [nzMax]="100000"
                  [nzFormatter]="formatterBol"
                  [nzParser]="parserBol"
                  [nzDisabled]="true"
                  style="width: 110px"
                ></nz-input-number>
              </nz-space>
            </div>
          </div>
          <div class="grid" *ngIf="false">
            <div class="col">
              <nz-form-label>Descuento del 13% RC IVA?</nz-form-label>
            </div>
            <div class="col">
              <nz-space nzSize="small">
                <nz-radio-group formControlName="pvIva" (ngModelChange) = "onChangeIvaGstRpr($event)" *nzSpaceItem>
                  <label nz-radio [nzValue]="false">No</label>
                  <label nz-radio [nzValue]="true">Si</label>
                </nz-radio-group>
                <nz-input-number
                  *nzSpaceItem
                  formControlName="pvIvaImporte"
                  [nzMin]="0"
                  [nzMax]="100000"
                  [nzFormatter]="formatterBol"
                  [nzParser]="parserBol"
                  [nzDisabled]="true"
                  style="width: 110px"
                ></nz-input-number>
              </nz-space>
            </div>
          </div>
          <div class="grid">
            <div class="col">
              <nz-form-label>Total gastos de representación</nz-form-label>
            </div>
            <div class="col">
              <nz-input-number
                formControlName="pvGastosRepreTotal"
                [nzMin]="0"
                [nzMax]="100000"
                [nzFormatter]="formatterBol"
                [nzParser]="parserBol"
                [nzDisabled]="true"
                style="width: 110px"
              ></nz-input-number>
            </div>
          </div>
          <div class="grid" *ngIf="false">
            <div class="col">
              <nz-form-label>Retención RC-IVA</nz-form-label>
            </div>
            <div class="col">
              <nz-input-number
                formControlName="pvRetencion"
                [nzMin]="0"
                [nzMax]="100000"
                [nzFormatter]="formatterBol"
                [nzParser]="parserBol"
                [nzDisabled]="true"
                style="width: 110px"
              ></nz-input-number>
            </div>
          </div>
          <div class="grid">
            <div class="col">
              <nz-form-label>Total viáticos y gastos de representación</nz-form-label>
            </div>
            <div class="col">
              <nz-input-number
                formControlName="pvGranTotal"
                [nzMin]="0"
                [nzMax]="100000"
                [nzFormatter]="formatterBol"
                [nzParser]="parserBol"
                [nzDisabled]="true"
                style="width: 110px"
              ></nz-input-number>
            </div>
          </div>
          <nz-divider nzText="Detalle de Cálculos para la Resolución" nzOrientation="left"></nz-divider>
          <div class="grid">
            <div class="col">
              <nz-form-label>Total viáticos</nz-form-label>
            </div>
            <div class="col">
              <nz-space nzSize="middle">
                <nz-input-number
                  *nzSpaceItem
                  formControlName="pvTvUsd"
                  [nzMin]="0"
                  [nzMax]="100000"
                  [nzFormatter]="formatterSus"
                  [nzParser]="parserSus"
                  [nzDisabled]="true"
                  style="width: 110px"
                ></nz-input-number>
                <nz-input-number
                  *nzSpaceItem
                  formControlName="pvTvBs"
                  [nzMin]="0"
                  [nzMax]="100000"
                  [nzFormatter]="formatterBol"
                  [nzParser]="parserBol"
                  [nzDisabled]="true"
                  style="width: 110px"
                ></nz-input-number>
              </nz-space>
            </div>
          </div>
          <div class="grid">
            <div class="col">
              <nz-form-label>Total gastos de representación</nz-form-label>
            </div>
            <div class="col">
              <nz-space nzSize="middle">
                <nz-input-number
                  *nzSpaceItem
                  formControlName="pvTgrUsd"
                  [nzMin]="0"
                  [nzMax]="100000"
                  [nzFormatter]="formatterSus"
                  [nzParser]="parserSus"
                  [nzDisabled]="true"
                  style="width: 110px"
                ></nz-input-number>
                <nz-input-number
                  *nzSpaceItem
                  formControlName="pvTgrBs"
                  [nzMin]="0"
                  [nzMax]="100000"
                  [nzFormatter]="formatterBol"
                  [nzParser]="parserBol"
                  [nzDisabled]="true"
                  style="width: 110px"
                ></nz-input-number>
              </nz-space>
            </div>
          </div>
          <div class="grid">
            <div class="col">
              <nz-form-label>Total pasajes</nz-form-label>
            </div>
            <div class="col">
              <nz-space nzSize="middle">
                <nz-input-number
                  *nzSpaceItem
                  formControlName="pvTpUsd"
                  [nzMin]="0"
                  [nzMax]="maxDias"
                  [nzFormatter]="formatterSus"
                  [nzParser]="parserSus"
                  [nzDisabled]="true"
                  style="width: 110px"
                ></nz-input-number>
                <nz-input-number
                  *nzSpaceItem
                  formControlName="pvTpBs"
                  [nzMin]="0"
                  [nzMax]="100000"
                  [nzFormatter]="formatterBol"
                  [nzParser]="parserBol"
                  [nzDisabled]="true"
                  style="width: 110px"
                ></nz-input-number>
              </nz-space>
            </div>
          </div>
          <div class="grid">
            <div class="col">
              <nz-form-label>Total viáticos, gastos de representación y pasajes	</nz-form-label>
            </div>
            <div class="col">
              <nz-space nzSize="middle">
                <nz-input-number
                  *nzSpaceItem
                  formControlName="pvTotalUsd"
                  [nzMin]="0"
                  [nzMax]="100000"
                  [nzFormatter]="formatterSus"
                  [nzParser]="parserSus"
                  [nzDisabled]="true"
                  style="width: 110px"
                ></nz-input-number>
                <nz-input-number
                  *nzSpaceItem
                  formControlName="pvTotalBs"
                  [nzMin]="0"
                  [nzMax]="100000"
                  [nzFormatter]="formatterBol"
                  [nzParser]="parserBol"
                  [nzDisabled]="true"
                  style="width: 110px"
                ></nz-input-number>
              </nz-space>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div *nzModalFooter>
  <button nz-button nzType="default" (click)="pre()" *ngIf="current > 0">
    <span>Anterior</span>
  </button>
  <button nz-button nzType="default" (click)="next()" [nzLoading]="processing" *ngIf="current < 3">
    <span>Siguiente</span>
  </button>
  <button nz-button nzType="primary" (click)="prcGestionSolicitudesPsjVts()" [nzLoading]="processing" *ngIf="current === 3">
    <span>Aceptar</span>
  </button>
</div>
