<div class="modal-theme">
  <mat-toolbar color="primary">
    <span>{{title}}</span>
    <span class="toolbar-spacer-custom"></span>
    <a>
      <i nz-icon nzType="close" nzTheme="outline" (click)="onNoClick({correcto: false})" style="color:silver"></i>
    </a>
  </mat-toolbar>
  <mat-stepper #stepper>
    <mat-step label="Registro de correo electrónico" [stepControl]="fgActCta">
      <nz-alert
        nzType="info"
        nzMessage="Información"
        nzDescription="Se enviara una solicitud de activación de cuenta al correo electrónico registrado."
        nzShowIcon
      ></nz-alert>
      <br />
      <form [formGroup]="fgActCta">
        <div class="p-grid">
          <mat-form-field class="p-col">
            <mat-label>Correo electrónico</mat-label>
            <span matPrefix><mat-icon matSuffix>mail</mat-icon></span>
            <input type="email" formControlName="correo" matInput placeholder="Correo electrónico">

          </mat-form-field>
        </div>
        <nz-divider></nz-divider>
        <div class="p-d-flex p-jc-end p-col-12">
          <button [disabled]="!fgActCta.valid" nz-button nzType="primary" matStepperNext>
            <span>Siguiente</span>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step label="Tipo de cuenta">
      <nz-alert
        nzType="warning"
        nzMessage="Atención"
        nzDescription="Elija el tipo de institución/organización/empresa que desea registrar para el NIT: {{nit}}."
        nzShowIcon
      ></nz-alert>
      <br />
      <mat-selection-list [(ngModel)]="tipoCta" [multiple]="false">
        <mat-list-option [value]="'conroe'">
          <mat-icon mat-list-icon>domain</mat-icon>
          <div mat-line>EMPRESAS <i nz-icon nz-tooltip nzTooltipTitle="Empresas que poseen NIT y Matrícula de Comercio,
          se obtendra información de la empresa a través de interoperabilidad con la OVT" nzType="info-circle"></i></div>
          <div mat-line>(Poseen NIT y Matrícula de Comercio)</div>
        </mat-list-option>
        <mat-list-option [value]="'sinroe'">
          <mat-icon mat-list-icon>domain_add</mat-icon>
          <div mat-line>EMPRESA SIN ROE  <i nz-icon nz-tooltip nzTooltipTitle="Empresas que por su Naturaleza jurídica no estan obligadas a contar con ROE,
          deberá registrar de manera manual la información requerida" nzType="info-circle"></i></div>
          <div mat-line>(Deberá registrar de manera manual la información requerida)</div>
        </mat-list-option>
      </mat-selection-list>
      <nz-divider></nz-divider>
      <div class="p-d-flex p-jc-end p-col-12">
        <nz-space>
          <button *nzSpaceItem nz-button nzType="default" matStepperPrevious>
            <span>Anterior</span>
          </button>
          <button *nzSpaceItem [disabled]="!tipoCta" [nzLoading]="loading" nz-button nzType="primary" (click)="validaTipoCta()">
            <span>Registrar</span>
          </button>
        </nz-space>
      </div>
    </mat-step>
  </mat-stepper>
</div>
