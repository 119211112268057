import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {message} from "../../../../environments/const.component";
import {OvtService} from "../../../core/services/ovt/ovt.service";
import {UsuariosService} from "../../../core/services/admin/usuarios.service";

@Component({
  selector: 'app-dlg-activar-cuenta',
  templateUrl: './dlg-activar-cuenta.component.html',
  styleUrls: ['./dlg-activar-cuenta.component.css']
})
export class DlgActivarCuentaComponent implements OnInit {
  public title: string = '';
  public nit: string = '';
  public fgActCta: FormGroup | any;
  public loading: boolean = false;
  public tipoCta: any;

  constructor(public dialogRef: MatDialogRef<DlgActivarCuentaComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private notification: NzNotificationService,
              private frmBuilder: FormBuilder,
              private srvUsr: UsuariosService,
              private wsOVT: OvtService) {
  }

  ngOnInit(): void {
    this.notification.create('success', 'Autenticación correcta!', 'Bienvenido a la Plataforma de Presentación de Programas de Seguridad y Salud en el Trabajo - PSST');
    this.title = this.data.title;
    this.nit = this.data.nit;
    this.fgActCta = this.frmBuilder.group({
      nit: [this.data.nit],
      correo: [null, Validators.required],
      conRoe: [null],
      estado: ['INICIAL'],
      transaccion: ['ADICIONAR'],
      login: [sessionStorage.getItem('login')]
    });
  }

  public onNoClick(res: any = null): void {
    this.dialogRef.close(res);
  }

  public validaTipoCta(): void {
    this.loading = true;
    let vConRoe;
    this.tipoCta[0] === 'conroe' ? vConRoe = true : vConRoe = false;
    this.fgActCta.controls.conRoe.setValue(vConRoe);

    if (vConRoe) {
      this.wsOVT.verificaRegistroOVT(this.nit).subscribe(res => {
          if (res.correcto && res.datoAdicional.length > 0) {
            this.activarCuenta();
          } else {
            this.loading = false;
            this.notification.create('error', 'Error', 'No cuenta con ROE en la OVT, para realizar su registro ingrese al siguiente enlace <a href="https://ovt.mintrabajo.gob.bo/#/loginRoe">https://ovt.mintrabajo.gob.bo/#/loginRoe</a>');
          }
        },
        err => {
          this.loading = false;
          // @ts-ignore
          this.notification.create('error', 'Error', message[err.status]);
        });
    } else {
      this.activarCuenta();
    }
  }

  public activarCuenta(): void {
    this.srvUsr.admUsuariosPsst(this.fgActCta.value).subscribe(res => {
        if (res.correcto) {
          this.emailActivarCuenta();
        } else {
          this.loading = false;
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }

  public emailActivarCuenta(): void {
    const pData = [{
      "email": this.fgActCta.controls.correo.value,
      "nit": this.fgActCta.controls.nit.value,
      "usr":false
    }]
    this.srvUsr.emailActCta(pData).subscribe(res => {
        if (res.correcto) {
          this.onNoClick(res);
        } else {
          this.loading = false;
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }
}
