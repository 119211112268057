<div novalidate class="modal-theme">
  <mat-toolbar color="primary">
    <span>{{title}}</span>
    <span class="toolbar-spacer-custom"></span>
    <a>
      <i nz-icon nzType="close" nzTheme="outline" (click)="onNoClick({correcto: false})" style="color:silver"></i>
    </a>
  </mat-toolbar>
  <div mat-dialog-content>
    <mat-card class="content-tab mat-elevation-z0">
      <mat-card-content>
        <form [formGroup]="fgDatosGrals">
          <span nz-typography><strong>Datos de la Empresa o Establecimiento Laboral</strong></span>
          <div class="p-grid">
            <mat-form-field class="p-col">
              <mat-label>Razón social:</mat-label>
              <input matInput formControlName="razonSocial">
            </mat-form-field>
          </div>
          <div class="p-grid">
            <mat-form-field class="p-col">
              <mat-label>Número de NIT:</mat-label>
              <input matInput formControlName="nit" type="text">
            </mat-form-field>
            <mat-form-field class="p-col">
              <mat-label>Número ROE:</mat-label>
              <input matInput formControlName="codigoMteps" type="text">
            </mat-form-field>
          </div>
          <div class="p-grid">
            <mat-form-field class="p-col">
              <mat-label>Actividad declarada</mat-label>
              <textarea matInput formControlName="actividadDeclarada"></textarea>
            </mat-form-field>
          </div>
          <div class="p-grid">
            <mat-form-field class="p-col">
              <mat-label>Dirección de Domicilio Legal de la Empresa o Establecimiento Laboral</mat-label>
              <textarea matInput formControlName="direccion"></textarea>
            </mat-form-field>
          </div>
          <div class="p-grid">
            <mat-form-field class="p-col">
              <mat-label>Representante Legal</mat-label>
              <input matInput formControlName="representanteLegal" type="text">
            </mat-form-field>
            <mat-form-field class="p-col">
              <mat-label>N° Trabajadores</mat-label>
              <input matInput formControlName="totalTrabajadores" type="text">
            </mat-form-field>
          </div>
          <span nz-typography><strong>Datos adicionales Proyecto, Casa Matriz, Sucursal o Repartición</strong></span>
          <br>
          <br>
          <div class="p-grid">
            <div class="p-col">
              <label nz-checkbox formControlName="proyectoEjecucion" (ngModelChange)="pryEjecucion($event)">Cuenta con Proyectos de Construcción en
                Ejecución</label>
            </div>
          </div>
          <div class="p-grid">
            <mat-form-field class="p-col">
              <mat-label>Nombre Proyecto</mat-label>
              <input matInput formControlName="nombreProyecto" type="text">
            </mat-form-field>
          </div>
          <div class="p-grid">
            <div class="p-col">
              <label nz-checkbox formControlName="tieneSubcontratados">Cuenta con Empresas Subcontratadas</label>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col">
              <p-chips placeholder="Empresas Subcontratadas" formControlName="empresasSubcontratadas" separator=","></p-chips>
            </div>
          </div>
          <br>
          <div class="p-grid">
            <nz-form-item class="p-col">
              <nz-form-control nzErrorTip="Seleccione la Casa Matriz, Sucursal o Repartición">
                <nz-select formControlName="sucursalReparticionId" nzShowSearch nzAllowClear
                           nzPlaceHolder="Casa Matriz, Sucursal o Repartición"
                           nzSize="default">
                  <nz-option *ngFor="let suc of lstSucursales" [nzLabel]="suc.nombreSucursal"
                             [nzValue]="suc.idSucursal"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="p-grid">
            <mat-form-field class="p-col">
              <mat-label>Cantidad Trabajadores Másculinos</mat-label>
              <input matInput formControlName="cantidadMasculino" type="number">
            </mat-form-field>
            <mat-form-field class="p-col">
              <mat-label>Cantidad Trabajadoras Femeninas</mat-label>
              <input matInput formControlName="cantidadFemenino" type="number">
            </mat-form-field>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-actions>
    <div class="p-d-flex p-jc-end p-col-12 mx-6">
      <nz-space>
        <div *nzSpaceItem>
          <button nz-button nzType="default" nzDanger
                  (click)="srvGestionPresentacionesPsst('RECHAZAR')">
            <span>Rechazar</span>
          </button>
        </div>
        <div *nzSpaceItem>
          <button nz-button nzType="primary"
                  (click)="srvConfirmarPrfSyso('CONFIRMAR')">
            <span>Confirmar</span>
          </button>
        </div>
      </nz-space>
    </div>
  </div>
</div>
