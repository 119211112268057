import { Component, OnInit } from '@angular/core';
import {LoginService} from "../../core/services/auth/login.service";
import {Router} from "@angular/router";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {message} from "../../../environments/const.component";
import {NzMessageService} from "ng-zorro-antd/message";
import {DlgActivarCuentaComponent} from "../login-psst/dlg-activar-cuenta/dlg-activar-cuenta.component";
import {MatDialog} from "@angular/material/dialog";
import {DlgConfirmarPsstComponent} from "./dlg-confirmar-psst/dlg-confirmar-psst.component";

@Component({
  selector: 'app-login-syso',
  templateUrl: './login-syso.component.html',
  styleUrls: ['./login-syso.component.css']
})
export class LoginSysoComponent implements OnInit {
  public alert: string = 'Cargando...';
  public success: boolean = false;
  public err403: boolean = false;
  public error: boolean = false;

  constructor(private srvAuth: LoginService,
              private route: Router,
              private notification: NzNotificationService,
              public modal: MatDialog,
              private router: Router) { }

  ngOnInit(): void {
    this.validaActivacionCuenta();
  }
  public validaActivacionCuenta(): void {
    this.srvAuth.authProfesionalSyso(sessionStorage.getItem('usr')).subscribe(res => {
        if (res.correcto) {
            const pModal = {title: 'CONFIRMACIÓN PSST', nit: sessionStorage.getItem('login')};
          const dlgActCta = this.modal.open(DlgConfirmarPsstComponent, {
            panelClass: 'dlgModal',
            disableClose: true,
            width: '800px',
            data: pModal
          });
          dlgActCta.afterClosed().subscribe(res => {
            if (res.correcto) {
              this.err403 = false;
              this.success = true;
              this.error = false;
              this.notification.create('success', 'Correcto!', res.notificacion);
            }else{
              this.err403 = false;
              this.success = false;
              this.error = true;
              this.notification.create('error', 'Error', res.notificacion);
            }
          });

        } else {
          sessionStorage.clear();
          this.err403 = true;
          this.success = false;
          this.error = false;
          this.notification.create('error', 'Error', message['403']);
        }
      },
      err => {
        sessionStorage.clear();
        this.err403 = true;
        this.success = false;
        this.error = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }
  public srvSistemaPsst(): void {
    this.router.navigate(['loginPsst']);
  }
}
