import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TransferItem} from "ng-zorro-antd/transfer";
import {message} from "../../../../../environments/const.component";
import {UsuariosService} from "../../../../core/services/admin/usuarios.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ClasificadorService} from "../../../../core/services/parametros/clasificador.service";

@Component({
  selector: 'app-dlg-activar-usuario',
  templateUrl: './dlg-activar-usuario.component.html',
  styleUrls: ['./dlg-activar-usuario.component.css']
})
export class DlgActivarUsuarioComponent implements OnInit {
  public title = null;
  public listPerfiles: Array<TransferItem> = [];
  public lstAmbito: any[] = [];
  public usuario: string = '';
  public cargo: string = '';
  public unidadArea: string = '';
  public ambito: number = 0;
  constructor(public dialogRef: MatDialogRef<DlgActivarUsuarioComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private srvUsr: UsuariosService,
              private srvClasificador: ClasificadorService,
              private notification: NzNotificationService) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.usuario = this.data.res.nombreCompleto;
    this.cargo = this.data.res.puesto;
    this.unidadArea = this.data.res.unidadArea;
    this.getData();
    this.getLstTipoAmbitos(2854);
  }
  public onNoClick(res: any = null): void {
    this.dialogRef.close(res);
  }


  getData(): void {
    this.listPerfiles = [
      {
        perfil: 'ADMIN_PSST',
        title: `Administrador`,
      },
      {
        perfil: 'CONSULTAS_PSST',
        title: `Consulta`,
      },
      {
        perfil: 'SEGURIDAD_OCUPACIONAL',
        title: `Seguridad Ocupacional`,
      }
    ];
  }
  public gestionWorkflow(): void {
    const pData = {
      estado: 'INICIAL',
      transaccion: 'ADICIONAR_PERUSR',
      login: this.data.res.usuario,
      correo: this.data.res.correo,
      perfil: this.listPerfiles,
      ambito: this.ambito,
      usuario: sessionStorage.getItem("login")
    };
    this.srvUsr.admUsuariosPsst(pData).subscribe(res => {
        if (res.correcto) {
          this.emailActivarCuenta(pData);
        } else {
          //this.loading = false;
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        //this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }
  public getLstTipoAmbitos(idClasificador: number): void {
    this.srvClasificador.lstClasificador(idClasificador).subscribe(res => {
        if (res.correcto) {
          this.lstAmbito = res.datoAdicional;
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        //this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }
  public emailActivarCuenta(pUsr: any): void {
    const pData = [{
      "email": "mcalzada@mintrabajo.gob.bo",//pUsr.correo,
      "usuario": pUsr.login,
      "usr": true
    }]
    this.srvUsr.emailActCta(pData).subscribe(res => {
        if (res.correcto) {
          this.onNoClick(res);
        } else {
          //this.loading = false;
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        //this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }
}
