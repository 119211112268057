import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LoginService} from '../core/services/auth/login.service';
import {MenuItem, MessageService} from 'primeng/api';
import {DlgFrmChangePassComponent} from '../shared/dlg-frm-change-pass/dlg-frm-change-pass.component';
import {MatDialog} from "@angular/material/dialog";
import {NzModalService} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  providers: [MessageService]
})
export class IndexComponent implements OnInit {
  public isCollapsed = false;
  title = 'mteps';
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  public viewSideNav: Observable<boolean> | any;
  public icon = 'fullscreen';
  appitems = [
    {
      label: 'Item 4',
      link: '/item-4',
      icon: 'star_rate',
      hidden: true
    }
  ];
  config = {
    paddingAtStart: true,
    listBackgroundColor: 'rgb(255,255,255)',
    fontColor: 'rgb(8, 54, 71)',
    backgroundColor: 'rgb(255,255,255)',
    selectedListFontColor: '#2f588e',
    highlightOnSelect: true,
  };
  public nombreCompleto = '';
  public orgUnidadFuncional = '';
  public pueNombre = '';
  items: MenuItem[] | any;
  public lstMenu: any[] = [];

  constructor(private breakpointObserver: BreakpointObserver,
              public router: Router,
              public dialog: MatDialog,
              private srvLogin: LoginService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.obtieneDatosUsuario();
    this.items = [
      {
        label: 'Menú',
        icon: 'pi pi-fw pi-home',
        expanded: true,
        items: [
          {
            label: 'Tickets',
            icon: 'pi pi-fw pi-file',
            routerLink: ['/tickets/tickets-soporte']
          },
          {
            label: 'Sitra',
            icon: 'pi pi-fw pi-file',
            routerLink: ['/sitra/tramites']
          }
        ]
      }
    ];
    this.obtieneMenuPorUsuario();
  }

  obtieneDatosUsuario(): void {
    this.srvLogin.obtDatosUsuarioPorLogin(sessionStorage.getItem('login')).subscribe((res => {
      if (res.correcto) {
        this.nombreCompleto = res.datoAdicional[0].nombreCompleto;
        this.pueNombre = res.datoAdicional[0].pueNombre;
        sessionStorage.setItem('nombreCompleto', this.nombreCompleto);
        sessionStorage.setItem('cargo', this.pueNombre);
        sessionStorage.setItem('ext', res.datoAdicional[0].externo);
        sessionStorage.setItem('lvl', res.datoAdicional[0].nivel);
      } else {
        this.logout();
      }
    }));
  }

  public cambiarContra(param: any): void {
    const pModal = {title: 'CAMBIO DE CONTRASEÑA'};
    const dlgFrmSucursal = this.dialog.open(DlgFrmChangePassComponent, {
      panelClass: 'dlgModal',
      width: '400px',
      disableClose: true,
      data: pModal
    });
    dlgFrmSucursal.afterClosed().subscribe(result => {
      if (result.correcto) {
        this.messageService.add({
          severity: 'primary',
          summary: 'Éxito',
          detail: result.notificacion,
          icon: 'pi-times-circle'
        });
        //  this.logout();
      } else {
        this.messageService.add({severity: 'err', summary: 'Error', detail: result.notificacion, icon: 'pi-times'});
      }
    });
  }

  /*
  Funciones
  */
  public logout(): void {
    this.srvLogin.logout();
  }

  public selectedMenu(e: any): void {
    // if (e.link) {
    this.router.navigate(['/sitra/tramites']);
    // }
  }

  public show(): void {
    console.log('Error');
  }
  public obtieneMenuPorUsuario(): void {
    this.srvLogin.obtMenu(sessionStorage.getItem('login')).subscribe((res => {
      if (res.correcto) {
        this.lstMenu = res.datoAdicional.menu.items;
      } else {
        this.logout();
      }
    }));
  }
}
