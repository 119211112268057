import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {LoginService} from "../../core/services/auth/login.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: LoginService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.LoginStatus) {
      sessionStorage.setItem('login', route.queryParams.usuario);
      sessionStorage.setItem('token', route.queryParams.tkn);
      sessionStorage.setItem('usr', route.queryParams.usr);
      sessionStorage.setItem('auth', 'true');
      sessionStorage.setItem('acct', 'true');
      route.queryParams.usr=="true"? this.router.navigate(['login']) : this.router.navigate(['loginPsst']);
    }
    return this.authService.LoginStatus;
  }

}
