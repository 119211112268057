import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {merge, Observable, timer} from 'rxjs';
import {delay, finalize, map, scan} from 'rxjs/operators';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {SyncStepInterface} from '../../../../core/models/steps/sync-step-interface';
import {AsyncStepInterface} from '../../../../core/models/steps/async-step-interface';

type Step = SyncStepInterface | AsyncStepInterface;
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment, Moment} from 'moment';
import {SolicitudesService} from '../../../../core/services/pasajes-viaticos/solicitudes.service';
import {ClasificadorService} from '../../../../core/services/parametros/clasificador.service';
import {UsuariosService} from '../../../../core/services/admin/usuarios.service';
import {message} from '../../../../../environments/const.component';

const moment = _rollupMoment || _moment;

function mockAsyncStep(): Observable<number> {
  const subStep1 = timer(600).pipe(map(() => 25));
  const subStep2 = subStep1.pipe(delay(600));
  const subStep3 = subStep2.pipe(delay(600));
  const subStep4 = subStep3.pipe(delay(600));
  return merge(subStep1, subStep2, subStep3, subStep4).pipe(scan((a, b) => a + b));
}

@Component({
  selector: 'app-informe',
  templateUrl: './informe.component.html',
  styleUrls: ['./informe.component.css']
})
export class InformeComponent implements OnInit {

  public isConfirmLoading = false;
  public isTipoViajeExt = false;
  public fgDatosGrals: FormGroup | any;
  public optEstipendios: any[] = [];
  public optDestinos: any[] = [];
  public optDeptos: any[] = [];
  public optPersonal: any[] = [];
  public isViaticosPrevio = false;
  public isAnexaRciva = false;
  public value = null;
  public loadUsers = true;
  // public ranges = { Today: [new Date(), new Date()], 'This Month': [new Date(), endOfMonth(new Date())] };
  public formatterBol = (value: number): string => `Bs. ${value}`;
  public parserBol = (value: string): string => value.replace('Bs. ', '');
  public formatterDias = (value: number): string => `${value} días`;
  public parserDias = (value: string): string => value.replace(' días', '');
  public steps: Step[] = [
    {
      id: 1,
      title: `Paso 1`,
      description: `Declaratoria en Comisión`,
      async: false,
      percentage: null
    },
    {
      id: 2,
      title: `Paso 2`,
      description: `Declaración/Autorización.`,
      async: true,
      percentage: 0
    },
    {
      id: 3,
      title: `Paso 3`,
      description: `Solicitud de Pasajes`,
      async: true,
      percentage: 0
    },
    {
      id: 4,
      title: `Paso 4`,
      description: `Liquidación de Viaticos`,
      async: true,
      percentage: 0
    }
  ];
  public current = 0;
  public processing = false;
  public html: string = '<p><strong>1.- ANTECEDENTES</strong></p><p>(Señalar los antecedentes del viaje, la nota de designación, fecha y el motivo del viaje)</p><br>' +
    '<p><strong>2.- OBJETIVO</strong></p><p>(Se debe señalar cual el objetivo del viaje)</p><br>' +
    '<p><strong>3.- DETALLE DEL VIAJE</strong></p><p>(Efectuar informe en detalle y por día de las actividades realizadas en función del objetivo del viaje, desde la fecha y hora de salida hasta la fecha y hora de llegada)</p><br>' +
    '<p><strong>4.- RESULTADOS OBTENIDOS</strong></p><p>(Señalar el valor agregado de la actividad realizada al objetivo del POA correspondiente y cual el grado de contribución a los objetivos estratégicos. Señalar resultados cualitativos y cuantitativos)</p><br>' +
    '<p><strong>5.- OBSERVACIONES</strong></p><p>(Señalar en caso de modificación de itinerario y otros aspectos relevantes que modificaron la programación del viaje y otros)</p><br>';
  @Input() title?: string;
  @Input() content?: any;
  @Input() edit?: boolean;
  @Input() transaccion?: string;
  public usrSigec: any;

  constructor(private frmBuilder: FormBuilder,
              private modal: NzModalRef,
              private cnf: NzModalService,
              private notification: NzNotificationService,
              private srv: SolicitudesService,
              private clf: ClasificadorService,
              private usr: UsuariosService) {
  }

  ngOnInit(): void {
    this.fgDatosGrals = this.frmBuilder.group({
      idSolicitud: [this.content.idSolicitud],
      idDescargo: [null],
      nombreCompleto: [{value: sessionStorage.getItem('nombreCompleto'), disabled: true}],
      cargo: [{value: sessionStorage.getItem('cargo'), disabled: true}],
      desInforme: [this.html],
      desRec: [null],
      desVias: [null],
      desViaticosPrevio: [null],
      desAnexaRciva: [null],
      desImporteFacturas: [0],
      desReembolso: [0],
      observacion: [null],
      transaccion: [this.transaccion],
      estado: [this.content.estado],
      login: [sessionStorage.getItem('login')],
      citeInf: [null],
      nurInf: [null]
    });
    this.prcOptionsUsr();
    this.prcUsrSigec();
    this.fgDatosGrals.controls.desAnexaRciva.setValue(false);
    this.fgDatosGrals.controls.desViaticosPrevio.setValue(false);
  }

  public destroyModal(result: any = {}): void {
    this.modal.destroy({data: result});
  }

  public prcOptionsUsr(): void {
    this.usr.getUsuariosSigec().subscribe(res => {
        if (res.correcto) {
          this.optPersonal = res.datoAdicional;
          this.loadUsers = false;
          // if (this.data.edit) {
          //   this.fgDatosGrals.controls.idClasificadorTramite.setValue(this.data.res.idClasificadorTramite);
          // }
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', 'err');
      });
  }

  public prcOptDestinosNivel(value: number): void {
    const vEstiNivel = this.optEstipendios.filter(x => x.idEstipendio === value);
    this.fgDatosGrals.controls.destinoId.setValue(null);
    value < 7 ? this.isTipoViajeExt = true : this.isTipoViajeExt = false;

    this.srv.srvDestinosNivel({estiNivel: vEstiNivel[0].estNivel}).subscribe(res => {
        if (res.correcto) {
          this.optDestinos = res.datoAdicional;
          if (this.edit) {
            this.fgDatosGrals.controls.destinoId.setValue(this.content.destinoId);
          }
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', 'err');
      });
  }

  public prcCalculoDias(val1: string, val2: string, val3: string): void {
    const prm = {
      fecIni: val1,
      fecFin: val2,
      diasRen: val3
    };
    this.srv.srvCalculoDias(prm).subscribe(res => {
        if (res.correcto) {
          this.fgDatosGrals.controls.pvNumeroDias.setValue(res.datoAdicional[0].totalDias);
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', 'err');
      });
  }

  public prcGestionSolicitudesPsjVts(): void {
    this.cnf.confirm({
      nzTitle: '<i>Atención!</i>',
      nzContent: '<b>Esta segura(o) de realizar el registro?</b>',
      nzOnOk: () => {
        const prm = {
          "usuario":sessionStorage.getItem('login'),
          "idVia": 0,
          "idDestinatario":755,
          "idTipoDocumento": 3,
          "referencia":"Informe de Viaje",
          "fojas": 0,
          "hojaRuta": true,
          "proveido":"Para su atención."
        }
        if(this.transaccion === 'GENERAR_INF'){
          this.srv.srvGeneraCiteSigec(prm).subscribe(res => {
              if (res.correcto) {
                this.fgDatosGrals.controls.citeInf.setValue(res.datoAdicional.cite);
                this.fgDatosGrals.controls.nurInf.setValue(res.datoAdicional.hojaRuta);
                this.srv.srvGestionSolicitudesPsjViaticos(this.fgDatosGrals.value).subscribe(res => {
                    if (res.correcto) {
                      this.destroyModal(res);
                    } else {
                      this.notification.create('error', 'Error', res.notificacion);
                    }
                  },
                  err => {
                    // @ts-ignore
                    this.notification.create('error', 'Error', message[err.status]);
                  });
              } else {
                this.notification.create('error', 'Error', res.notificacion);
              }
            },
            err => {
              // @ts-ignore
              this.notification.create('error', 'Error', message[err.status]);
            });
        }
        else{
          this.srv.srvGestionSolicitudesPsjViaticos(this.fgDatosGrals.value).subscribe(res => {
              if (res.correcto) {
                this.destroyModal(res);
              } else {
                this.notification.create('error', 'Error', res.notificacion);
              }
            },
            err => {
              // @ts-ignore
              this.notification.create('error', 'Error', message[err.status]);
            });
        }
      }
    });
  }

  public onChange(result: Date[]): void {
    this.fgDatosGrals.controls.pvFechaHoraPartida.setValue(moment(result[0]).format('DD/MM/YYYY HH:mm:ss'));
    this.fgDatosGrals.controls.pvFechaHoraRetorno.setValue(moment(result[1]).format('DD/MM/YYYY HH:mm:ss'));
    this.prcCalculoDias(moment(result[0]).format('DD/MM/YYYY HH:mm'), moment(result[1]).format('DD/MM/YYYY HH:mm'), this.fgDatosGrals.controls.pvDiasRenunciaViatico.value);
  }
  public prcUsrSigec(): void {
    this.usr.getUsuariosSigec().subscribe(res => {
        if (res.correcto) {
          this.usrSigec = res.datoAdicional;
          sessionStorage.setItem('usrsigec', (this.usrSigec.filter((x: any) => x.username === sessionStorage.getItem('login')))[0].id);
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', 'err');
      });
  }

}
