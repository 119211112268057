import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {ResultInterface} from "../../models/auth/result-interface";
import {endPoint} from "../../../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import {ServiceExceptionService} from "../service-exception.service";
import {httpOptions} from "../../../../environments/const.component";

@Injectable({
  providedIn: 'root'
})
export class DocumentosService extends ServiceExceptionService  {

  constructor(private http: HttpClient) {
    super();
  }
  public lstDocumentos(modulo: string): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/psst/listadocumentos?modulo=${modulo}`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public lstDocumentosPresentacion(idPresentacionProyecto: number): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/psst/lstDocumentosPorPresentacion?idPresentacionProyecto=${idPresentacionProyecto}`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public gestionDocumentos(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/psst/gestiondocumento`, pData, httpOptions).pipe(
      tap(res => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

}
