<div [formGroup]="parentForm">
  <mat-form-field fxFlex *ngIf="visible">
    <input matInput [matDatepicker]="pickMmYyyy" [min]="fecMin" [max]="fecMax" placeholder="{{placeHolder}}"
           formControlName="{{frmCtrlName}}" required>
    <mat-datepicker-toggle matSuffix [for]="pickMmYyyy"></mat-datepicker-toggle>
    <mat-datepicker #pickMmYyyy startView="multi-year"
                    (yearSelected)="runYearHandler($event)"
                    (monthSelected)="runMonthHandler($event, pickMmYyyy)"
                    panelClass="example-month-picker"></mat-datepicker>
  </mat-form-field>
</div>
