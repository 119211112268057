<div *ngIf="ifStatusSin" style="position: absolute; top: 0; right: 0; width: 100%; z-index: 99">
  <nz-alert nzType="error" [nzMessage]="errStatusSin" nzShowIcon></nz-alert>
</div>
<div class="p-d-flex p-jc-center" style="height: 100%; padding: 10px">
  <div class="p-d-flex p-ai-center">
    <div class="p-d-flex p-flex-column p-flex-md-row">
      <div class="p-mb-2 p-mr-2" style="max-width: 350px">
        <form [formGroup]="frmAuthLogin">
          <p-card styleClass="p-card-shadow">
            <ng-template pTemplate="header">
              <div class="p-d-flex p-jc-center">
                <img src="../assets/LogoMteps.jpg" alt="Ministerio de Trabajo, Empleo y Previsión Social"
                     style="width: 30%">
              </div>
            </ng-template>
            <nz-form-item>
              <nz-form-control nzErrorTip="Por favor ingrese su NIT!">
                <nz-input-group [nzSuffix]="sfxNit" [nzPrefix]="pfxNit">
                  <input type="text" nz-input placeholder="NIT" formControlName="nit"/>
                </nz-input-group>
                <ng-template #pfxNit><i nz-icon nzType="idcard" nzTheme="twotone"></i></ng-template>
                <ng-template #sfxNit>
                  <i nz-icon nz-tooltip
                     nzTooltipTitle="El Número de Identificación Tributaria de su tarjeta MASI otorgado por el Servicio de Impuestos Nacionales"
                     nzType="info-circle"></i>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Por favor ingrese su nombre de usuario!">
                <nz-input-group [nzSuffix]="sfxUsr" [nzPrefix]="pfxUsr">
                  <input type="text" nz-input placeholder="Nombre de usuario" formControlName="usuario"/>
                </nz-input-group>
                <ng-template #pfxUsr><i nz-icon nzType="user" style="color: #2196F3;"></i></ng-template>
                <ng-template #sfxUsr>
                  <i nz-icon nz-tooltip
                     nzTooltipTitle="El nombre de usuario de su tarjeta MASI otorgado por el Servicio de Impuestos Nacionales"
                     nzType="info-circle"></i>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Por favor ingrese su contraseña!">
                <nz-input-group [nzSuffix]="suffixTemplate" [nzPrefix]="pfxPwd">
                  <input [type]="passwordVisible ? 'text' : 'password'" nz-input placeholder="Contraseña"
                         [(ngModel)]="password" formControlName="clave"/>
                </nz-input-group>
                <ng-template #pfxPwd><i nz-icon nzType="lock" nzTheme="twotone"></i></ng-template>
                <ng-template #suffixTemplate>
                  <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                     (click)="passwordVisible = !passwordVisible"></i>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
            <ng-template pTemplate="footer">
              <div class="p-d-flex p-jc-end">
                <p-button label="Acceder" [loading]="loading" (onClick)="validarForm()"></p-button>
              </div>
              <br>
              <p style="color: #085879; text-align: justify; font-size: small"><strong>Nota.-</strong> Si no recibió el
                correo de activación de cuenta, puede solicitar el re-envío del mismo ingresando al siguiente enlace
                <a
                  href="">Activar cuenta</a></p>
            </ng-template>
          </p-card>
        </form>
      </div>
      <div class="p-mb-2 p-mr-2">
        <div class="custom-card">
          <h2>¿Cómo ingresar?</h2>
          <p>Para ingresar a la plataforma PSST usted debe emplear los datos del <strong>NIT,
            usuario y contraseña</strong> con los que accede a la <strong>Oficina Virtual del Servicio de Impuestos
            Nacionales</strong>.</p>
          <h2>Guías y tutoriales</h2>
          <p class="anuncios-container">Ingrese al sitio de apoyo técnico:<br><a
            href="https://mintrabajo.gob.bo/psst" target="_blank">https://mintrabajo.gob.bo/psst</a></p>
          <h2>Si tiene dudas o consultas</h2>
          <p>Contáctese con el teléfono: (2)2-408606<br>
            o escriba al correo: <a href="mailto:soporteovt@mintrabajo.gob.bo">soporteovt@mintrabajo.gob.bo</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
