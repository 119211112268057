import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CheckLoginGuard} from './shared/guards/check-login.guard';
import {LoginComponent} from './auth/login/login.component';
import {IndexComponent} from './modules/index.component';
import {LoginPsstComponent} from "./auth/login-psst/login-psst.component";
import {DashboardComponent} from "./modules/dashboard/dashboard.component";
import {UsuariosComponent} from "./modules/admin/usuarios/usuarios.component";
import {ParametrosComponent} from "./modules/admin/parametros/parametros.component";
import {RolesComponent} from "./modules/admin/roles/roles.component";
import {PerfilesComponent} from "./modules/admin/perfiles/perfiles.component";
import {AuthGuard} from "./shared/guards/auth.guard";
import {AuthsysoGuard} from "./shared/guards/authsyso.guard";
import {LoginSysoComponent} from "./auth/login-syso/login-syso.component";
import {SolicitudesComponent} from "./modules/pasajes-viaticos/solicitudes/solicitudes.component";
import {LiquidacionComponent} from "./modules/pasajes-viaticos/liquidacion/liquidacion.component";
import {ReliquidacionComponent} from "./modules/pasajes-viaticos/reliquidacion/reliquidacion.component";
import {DescargosComponent} from "./modules/pasajes-viaticos/descargos/descargos.component";
import {RepSolicitudesComponent} from "./modules/dashboard/rep-solicitudes/rep-solicitudes.component";
import {RepAlertasComponent} from "./modules/dashboard/rep-alertas/rep-alertas.component";

const routes: Routes = [
  {
    path: 'viajar',
    component: IndexComponent,
    canActivate: [CheckLoginGuard],
    children: [
      {path: 'dashboard', component: DashboardComponent},
      {path: 'admin',
        children: [
          {path: 'usuarios', component: UsuariosComponent},
          {path: 'perfiles', component: PerfilesComponent},
          {path: 'roles', component: RolesComponent},
          {path: 'destinos', component: ParametrosComponent},
        ]
      },
      {path: 'pasajes-viaticos',
        children: [
          {path: 'solicitudes', component: SolicitudesComponent},
          {path: 'descargos', component: DescargosComponent},
        ]
      },
      {path: 'liquidacion-reliquidacion',
        children: [
          {path: 'liquidacion', component: LiquidacionComponent},
          {path: 'reliquidacion', component: ReliquidacionComponent}
        ]
      },
      {path: 'reportes',
        children: [
          {path: 'solicitudes', component: RepSolicitudesComponent},
          {path: 'alertas', component: RepAlertasComponent}
        ]
      },
      {path: 'dashboard',
        children: [
          {path: 'reportes', component: DashboardComponent},
        ]
      },
    ]
  },
  {
    path: 'auth',
    component: LoginPsstComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
