import {Injectable} from '@angular/core';
import {ResultInterface} from '../../models/auth/result-interface';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {httpOptions} from '../../../../environments/const.component';
import {endPoint} from "../../../../environments/environment";
import {ServiceExceptionService} from '../service-exception.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends ServiceExceptionService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private loggedInStatus = JSON.parse(sessionStorage.getItem('auth') || ('false'));

  constructor(private http: HttpClient, public router: Router) {
    super();
    sessionStorage.getItem('auth') ? this.loggedIn.next(true) : this.loggedIn.next(false);
  }

  get LoginStatus() {
    return JSON.parse(sessionStorage.getItem('loggedIn') ||
      this.loggedInStatus.toString());
  }

  get isLogged(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  public logout(): void {
    const session = sessionStorage.getItem('session');
    sessionStorage.clear();
    if (session == 'login') {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/loginPsst']);
    }
  }

  public authProfesionalSyso(idProfesional: any): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/psst/validaProfesionalSyso?idProfesional=${idProfesional}`).pipe(
      tap(res => {
        sessionStorage.setItem('nroreg', res.datoAdicional[0].numeroRegistro);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public authLoginMteps(data: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/login/`, data, httpOptions).pipe(
      tap(res => {
        this.loggedIn.next(true);
        sessionStorage.setItem('auth', 'true');
        sessionStorage.setItem('login', res.datoAdicional.username);
        sessionStorage.setItem('token', res.datoAdicional.accessToken);
        sessionStorage.setItem('session', 'login');
        // sessionStorage.setItem('departamento', 'COCHABAMBA');
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public authLoginEmpresa(data: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/sin/login`, data, httpOptions).pipe(
      tap(res => {
        if (res.correcto) {
          this.loggedIn.next(true);
          sessionStorage.setItem('auth', 'true');
          sessionStorage.setItem('login', res.datoAdicional.usuario);
          sessionStorage.setItem('token', res.datoAdicional.token);
          sessionStorage.setItem('session', 'loginPsst');
        }
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public wsActivacionCuenta(pUsr: string, pSyso: boolean = false): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/psst/estadoCuenta?usr=${pUsr}`).pipe(
      tap(res => {
        if (res.correcto) {
          // if(!pSyso)
          //   sessionStorage.setItem('ovt', res.datoAdicional[0].ovt);
        }
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public wsVerificaRegistroEmpresa(pData: string): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/empresa/verificaregistro`, pData, httpOptions).pipe(
      tap(res => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public wsActivarCuenta(pData: string): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/empresa/activarcuenta`, pData, httpOptions).pipe(
      tap(res => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public obtDatosUsuarioPorLogin(pLogin: any): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/pasajesviaticos/obtenerdatosusuario?login=${pLogin}`).pipe(
      tap(msg => {
      }),
      catchError(this.handleError<ResultInterface>('registraDocumento', {
        correcto: false,
        codigoResultado: 0,
        notificacion: undefined,
        datoAdicional: null
      }))
    );
  }

  public changePass(data: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/config/cambiarpassword/`, data, httpOptions).pipe(
      tap(res => {
      }),
      catchError(this.handleError<ResultInterface>('cambioContraseña', {
        correcto: false,
        codigoResultado: 0,
        notificacion: undefined,
        datoAdicional: null
      }))
    );
  }
  public obtMenu(pLogin: any): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/pasajesviaticos/lstmenu?usr=${pLogin}`).pipe(
      tap(msg => {
      }),
      catchError(this.handleError<ResultInterface>('obtMenu', {
        correcto: false,
        codigoResultado: 0,
        notificacion: undefined,
        datoAdicional: null
      }))
    );
  }
}
