import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dlg-nuevo-usuario',
  templateUrl: './dlg-nuevo-usuario.component.html',
  styleUrls: ['./dlg-nuevo-usuario.component.css']
})
export class DlgNuevoUsuarioComponent implements OnInit {
public title = null;
  constructor(public dialogRef: MatDialogRef<DlgNuevoUsuarioComponent>,
    public dialog: MatDialog,
  @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.title = this.data.title;
  }
public onNoClick(res: any = null): void {
    this.dialogRef.close(res);
  }
}
