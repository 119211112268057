import {Component} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LoginService} from './core/services/auth/login.service';
import {NzModalService} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'mteps';
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  public viewSideNav: Observable<boolean> | any;

  constructor(private breakpointObserver: BreakpointObserver,
              public router: Router,
              private authSvc: LoginService
  ) {
  }
  ngOnInit(): void {
  }

  /*
  Constantes
  */
  public icon = 'fullscreen';

  appitems = [
    {
      label: 'Item 4',
      link: '/item-4',
      icon: 'star_rate',
      hidden: true
    }
  ];
  config = {
    paddingAtStart: true,
    // interfaceWithRoute: true,
    // classname: 'my-custom-class',
    listBackgroundColor: 'rgb(255,255,255)',
    fontColor: 'rgb(8, 54, 71)',
    backgroundColor: 'rgb(255,255,255)',
    selectedListFontColor: '#2f588e',
    highlightOnSelect: true,
    // collapseOnSelect: true,
    // rtlLayout: true
  };

  /*
  Funciones
  */
  public logout(): void {
  }

  public selectedMenu(e: any): void {
    if (e.link) {
      this.router.navigate([e.link]);
    }
  }
}
