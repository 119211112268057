import {Injectable} from '@angular/core';
import {ServiceExceptionService} from "../service-exception.service";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {ResultInterface} from "../../models/auth/result-interface";
import {endPoint} from "../../../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import {apiext, headerFormData, httpOptions} from "../../../../environments/const.component";

@Injectable({
  providedIn: 'root'
})
export class PresentacionesPsstService extends ServiceExceptionService {

  constructor(private http: HttpClient) {
    super();
  }

  public lstProfesionalesSyso(): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/psst/listaProfesionalesSyso`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public datosEmpresaPorNit(nit: string, ovt: boolean): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/psst/empresaSucursales?nit=${nit}&ovt=${ovt}`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public wsPsstPorNitEstado(nit: string, estado: string, ovt: boolean): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/psst/psstPorNitEstado?nit=${nit}&estado=${estado}&ovt=${ovt}`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public wsGestionPresentacionesPsst(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/psst/gestionPresentacionesPsst`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public enviarPsstProfesionalSyso(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/correo/emailConfirmacionPsstPrfSyso`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public wsObtenerMontoCpt(pIdPresentacionProyecto: number): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/psst/obtieneMontoCpt?idPresentacionProyecto=${pIdPresentacionProyecto}`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public wsGenerarCpt(pData: any): Observable<any> {
    return this.http.post<any>(`${apiext.endpoint_cpt}`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public wsUpload(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/psst/uploadDocumento`, pData, headerFormData).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public wsGestionRepositorioDoc(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/psst/gestionRepositorioDocumento`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public wsProfesionalSysoPorEstado(pEstado: number): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/psst/listaProfesionalSysoPorEstado?estado=${pEstado}`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public wsGestionProfesionalSyso(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/psst/gestionProfesionalSyso`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
