<div class="modal-theme">
  <mat-toolbar color="primary">
    <span>{{title}}</span>
    <span class="toolbar-spacer-custom"></span>
    <a>
      <i nz-icon nzType="close" nzTheme="outline" (click)="onNoClick({correcto: false})" style="color:silver"></i>
    </a>
  </mat-toolbar>
  <div mat-dialog-content>
    <mat-card class="content-tab mat-elevation-z0">
      <mat-card-content>
        <form nz-form [nzLayout]="'vertical'" [formGroup]="fgDoc">
          <nz-form-item>
            <nz-form-label>Nombre del Documento</nz-form-label>
            <nz-form-control nzErrorTip="Ingrese el nombre del Documento!">
              <input nz-input formControlName="nombre" placeholder="Ingrese el nombre del Documento"/>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label>Tamaño en KB</nz-form-label>
            <nz-form-control nzErrorTip="Ingrese el tamaño del archivo en KB!">
              <nz-input-number formControlName="pesoMaximo"></nz-input-number>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label>Orden</nz-form-label>
            <nz-form-control nzErrorTip="Ingrese la desripción del Perfil!">
              <nz-input-number formControlName="orden"></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-divider></mat-divider>
  <div class="p-d-flex p-jc-end p-col-12">
    <button nz-button nzType="default" (click)="onNoClick({correcto: false})">
      <span>Cancelar</span>
    </button>&nbsp;
    <button nz-button nzType="primary" (click)="gestionDocumento()">
      <span>Aceptar</span>
    </button>
  </div>
</div>
