<nz-layout class="sidenav-container">
  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" nzWidth="284px" [nzTheme]="'dark'">
    <div class="logo" *ngIf="!isCollapsed">
      <div class="flex flex-row flex-wrap card-container blue-container">
        <div class="flex align-items-center justify-content-center h-5rem border-round m-2">
          <img src="../assets/img-mteps-horizontal.jpg" alt="Ministerio de Trabajo, Empleo y Previsión Social">
        </div>
      </div>
    </div>
      <div class="flex flex-row flex-wrap card-container blue-container" *ngIf="isCollapsed">
        <div class="flex align-items-center justify-content-center w-6rem h-5rem border-round mt-2">
          <img src="../assets/LogoMteps50x60.jpg" alt="Ministerio de Trabajo, Empleo y Previsión Social">
        </div>
      </div>
    <ul nz-menu nzMode="inline" nzTheme="dark">
      <li nz-submenu  *ngFor="let m of lstMenu" [nzTitle]="m.descripcion" [nzIcon]="m.icon">
        <ul>
          <li *ngFor="let s1 of m.items" [hidden]="s1.link == '#'" nz-menu-item nzMatchRouter [routerLink]="[s1.link]">{{s1.descripcion}}</li>
          <li *ngFor="let s1 of m.items" [hidden]="s1.link != '#'" nz-submenu [nzTitle]="s1.descripcion" [nzIcon]="s1.icon">
            <ul>
              <li nz-menu-item nzMatchRouter *ngFor="let s2 of s1.items" [routerLink]="[s2.link]">{{s2.descripcion}}</li>
            </ul>
          </li>
        </ul>
      </li>
<!--      <li nz-submenu nzTitle="Mantenimiento y Configuración" nzIcon="setting" [nzOpen]="true">
        <ul>
          <li nz-menu-item nzMatchRouter [routerLink]="['/viajar/admin/usuarios']">Usuarios</li>
        </ul>
        <ul>
          <li nz-menu-item nzMatchRouter [routerLink]="['/viajar/admin/roles']">Roles</li>
        </ul>
        <ul>
          <li nz-menu-item nzMatchRouter [routerLink]="['/viajar/admin/perfiles']">Perfiles</li>
        </ul>
        <ul>
          <li nz-submenu nzMatchRouter nzIcon="bars" nzTitle="Parámetros">
            <ul>
              <li nz-menu-item nzMatchRouter [routerLink]="['/viajar/admin/destinos']">Destinos</li>
            </ul>
          </li>
        </ul>
      </li>
      <li nz-submenu nzTitle="Pasajes y Viáticos" nzIcon="snippets" [nzOpen]="false">
        <ul>
          <li nz-menu-item nzMatchRouter [routerLink]="['/viajar/pasajes-viaticos/solicitudes']">Mis Solicitudes</li>
        </ul>
        <ul>
          <li nz-menu-item nzMatchRouter [routerLink]="['/viajar/pasajes-viaticos/descargos']">Mis Descargos</li>
        </ul>
      </li>
      <li nz-submenu nzTitle="Liquidación y Reliquidación" nzIcon="diff" [nzOpen]="false">
        <ul>
          <li nz-menu-item nzMatchRouter [routerLink]="['/viajar/liquidacion-reliquidacion/liquidacion']">Liquidación</li>
        </ul>
        <ul>
          <li nz-menu-item nzMatchRouter [routerLink]="['/viajar/liquidacion-reliquidacion/reliquidacion']">Reliquidación</li>
        </ul>
      </li>
      <li nz-submenu nzTitle="Reportes" nzIcon="pie-chart" [nzOpen]="false">
        <ul>
          <li nz-menu-item nzMatchRouter [routerLink]="['/viajar/reportes/solicitudes']">Solicitud de Viaje</li>
        </ul>
        <ul>
          <li nz-menu-item nzMatchRouter [routerLink]="['/viajar/reportes/alertas']">Alertas</li>
        </ul>
      </li>-->
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <mat-toolbar color="primary">
          <span
            class="trigger"
            nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            (click)="isCollapsed = !isCollapsed"
          ></span>
        <span></span>
        <span class="toolbar-spacer-custom"></span>
        <span style="font-size:small">{{nombreCompleto}}</span>
        <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Cuenta">
          <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu" yPosition="below">
          <button mat-menu-item>
            <mat-icon>person</mat-icon>
            <span>Perfil</span>
          </button>
          <button mat-menu-item>
            <mat-icon>help</mat-icon>
            <span>Ayuda</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Cerrar Sesión</span>
          </button>
        </mat-menu>
      </mat-toolbar>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <nz-footer>©2023 Ministerio de Trabajo, Empleo y Previsión Social</nz-footer>
  </nz-layout>
</nz-layout>
