import {Component, Inject, OnInit} from '@angular/core';
import {TransferItem} from "ng-zorro-antd/transfer";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UsuariosService} from "../../../../core/services/admin/usuarios.service";
import {ClasificadorService} from "../../../../core/services/parametros/clasificador.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {message} from "../../../../../environments/const.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-dlg-registrar-perfil',
  templateUrl: './dlg-registrar-perfil.component.html',
  styleUrls: ['./dlg-registrar-perfil.component.scss']
})
export class DlgRegistrarPerfilComponent implements OnInit {
  public title = null;
  public listPerfiles: Array<TransferItem> = [];
  public lstRoles: any [] = [];
  public fgPerfil: FormGroup | any;
  public isHorizontal: boolean = true;
  public listOfSelectedValue = null;
  public isEdit: boolean = false;
  public valPerfil: string = '';

  constructor(public dialogRef: MatDialogRef<DlgRegistrarPerfilComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private srvUsr: UsuariosService,
              private srvClasificador: ClasificadorService,
              private notification: NzNotificationService,
              private frmBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.fgPerfil = this.frmBuilder.group({
      perfil: [null, Validators.required],
      descripcionPerfil: [null, Validators.required],
      roles: [null, Validators.required],
      lstRol: [null],
      estado: ['INICIAL'],
      transaccion: ['ADD_PERFIL'],
      login: [sessionStorage.getItem('login')]
    });
    if (this.data.res) {
      this.fgPerfil.controls.perfil.setValue(this.data.res.perfil);
      this.fgPerfil.controls.descripcionPerfil.setValue(this.data.res.descripcionPerfil);
      this.listOfSelectedValue = (this.data.res.rol).split(',');
      this.fgPerfil.controls.transaccion.setValue(this.data.trns);
      this.isEdit = true;
      this.valPerfil = this.data.res.perfil;
    }
    this.pLstRoles('%');
  }

  public onNoClick(res: any = null): void {
    this.dialogRef.close(res);
  }

  public gestionWorkflow(): void {
    console.log(this.lstRoles.filter((x: { rol: string; }) => (this.fgPerfil.controls.roles.value).includes(x.rol)));
    this.fgPerfil.controls.lstRol.setValue(this.lstRoles.filter((x: { rol: string; }) => (this.fgPerfil.controls.roles.value).includes(x.rol)));
    if (this.fgPerfil.valid) {
      this.srvUsr.admUsuariosPsst(this.fgPerfil.value).subscribe(res => {
          if (res.correcto) {
            this.onNoClick(res);
          } else {
            //this.loading = false;
            this.notification.create('error', 'Error', res.notificacion);
          }
        },
        err => {
          //this.loading = false;
          // @ts-ignore
          this.notification.create('error', 'Error', message[err.status]);
        });
    } else {
      Object.values(this.fgPerfil.controls).forEach((control: any) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  public pLstRoles(pPerfil: string): void {
    this.srvUsr.lstRoles('PSST', pPerfil).subscribe((res => {
        if (res.correcto) {
          this.lstRoles = res.datoAdicional;
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      }
    ));
  }
}
