<div style="padding: 5px">
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <i nz-icon nzType="home"></i>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a routerLink="/viajar/reportes/alertas">
        <span>Reportes</span>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>Detalle de Alertas</nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-card [nzBordered]="false" nzTitle="DETALLE DE ALERTAS">
    <div class="p-fluid grid formgrid">
      <div class="field col-2">
        <label for="lblfecIni" class="text-sm">Desde:</label>
        <div id="lblfecIni">
          <nz-date-picker
            nzFormat="dd/MM/yyyy"
            nzPlaceHolder="Fecha desde"
            [(ngModel)]="fecIni"
          ></nz-date-picker>
        </div>
      </div>
      <div class="field col">
        <label for="lblfecFin" class="text-sm">Hasta:</label>
        <div id="lblfecFin">
          <nz-date-picker
            nzFormat="dd/MM/yyyy"
            nzPlaceHolder="Fecha hasta"
            [(ngModel)]="fecFin"
          ></nz-date-picker>
        </div>
      </div>
    </div>
    <div class="p-fluid grid formgrid">
      <div class="field col">
        <label for="selectTipoViaje" class="text-sm">Ambito:</label>
        <div id="selectTipoViaje">
          <nz-select  [(ngModel)]="idUsr" nzShowSearch nzAllowClear
                      nzPlaceHolder="Quién declara en comisión o viaje"
                      (ngModelChange)="prcSolicitudesPorUsrEstado($event)"
                      nzSize="default">
            <nz-option *ngFor="let opt of optPersonal" [nzLabel]="opt.nombreCompleto+' | '+opt.puesto"
                       [nzValue]="opt.usuario"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
    <p-table #dt
             dataKey="idSolicitud"
             [showCurrentPageReport]="true"
             [paginator]="true"
             [rows]="5"
             currentPageReportTemplate="Total registros {totalRecords}"
             [globalFilterFields]="['solNumero','solGestion','fechaCreacion','solObjetivo','destino','pvFechaHoraPartida','pvFechaHoraRetorno','estado']"
             [columns]="cols"
             [value]="rowsDataSol"
             responsiveLayout="stack"
             styleClass="p-datatable-sm"
             selectionMode="single"
             [(selection)]="selectedRow">
      <ng-template pTemplate="caption">
        <div class="flex">
          <div class="col-12 m-1 p-0" *ngIf="isFilter">
            <nz-input-group [nzSuffix]="inputClearTpl" [nzPrefix]="prefixSearch">
              <input nz-input placeholder="Ingrese el texto para inciar la busqueda..."
                     (input)="dt.filterGlobal($any($event.target).value, 'contains')"/>
            </nz-input-group>
            <ng-template #prefixSearch><span nzSize="small" nz-icon nzType="search"></span></ng-template>
            <ng-template #inputClearTpl>
              <span
                nz-icon
                class="ant-input-clear-icon"
                nzTheme="fill"
                nzType="close-circle"
                *ngIf="true"
                (click)="isFilter = !isFilter; prcSolicitudesPorUsrEstado()"
              ></span>
            </ng-template>
          </div>
          <span class="p-input-icon-left ml-auto" *ngIf="!isFilter">
              <button mat-icon-button color="accent" matTooltip="Exportar Reporte (xls)" (click)="exportExcel()">
                <mat-icon>download</mat-icon>
              </button>
              <button mat-icon-button color="accent" matTooltip="Filtrar" (click)="isFilter = !isFilter">
                <mat-icon>filter_list</mat-icon>
              </button>
              <button mat-icon-button color="accent" matTooltip="Actualizar" (click)="prcSolicitudesPorUsrEstado(true)">
                <mat-icon>refresh</mat-icon>
              </button>
            </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
          <th style="width:10rem">Estado</th>
          <th style="width:10rem">Acciones</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            <span class="p-column-title font-bold">{{col.field}}</span>{{rowData[col.field]}}
          </td>
          <td>
            <span *ngIf="!rowData.estadoInf" nz-icon [nzType]="'clock-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#bd0e0e'" [nz-tooltip]="rowData.diasInforme"></span>
            <span *ngIf="rowData.estadoInf" nz-icon [nzType]="'clock-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'" [nz-tooltip]="rowData.diasInforme"></span>
          </td>
          <td>
            <span *ngIf="rowData.estado === 'ENVIADO'">
              <button pButton pRipple type="button" icon="pi pi-file-pdf" (click)="prcGenerarFrmSolicitud(rowData)" class="p-button-rounded p-button-text p-button-success" pTooltip="Descargar formulario"></button>
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody" let-columns="columns">
        <tr>
          <td *ngFor="let col of columns; let even = even">
            <p-skeleton></p-skeleton>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </nz-card>
</div>
