import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {message} from "../../../../environments/const.component";
import {DlgConfirmComponent} from "../../../shared/dlg-confirm/dlg-confirm.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ClasificadorService} from "../../../core/services/parametros/clasificador.service";
import {PresentacionesPsstService} from "../../../core/services/psst/presentaciones-psst.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {MdValidarSysoComponent} from "../md-validar-syso/md-validar-syso.component";

@Component({
  selector: 'app-dlg-confirmar-psst',
  templateUrl: './dlg-confirmar-psst.component.html',
  styleUrls: ['./dlg-confirmar-psst.component.css']
})
export class DlgConfirmarPsstComponent implements OnInit {
  public title = null;
  public fgDatosGrals: FormGroup | any;
  public lstProfesionalesSyso: any[] = [];
  public lstSucursales: any[] = [];

  constructor(public dialogRef: MatDialogRef<DlgConfirmarPsstComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private frmBuilder: FormBuilder,
              private srvClasificador: ClasificadorService,
              private srvPresentaciones: PresentacionesPsstService,
              private notification: NzNotificationService) {
  }


  ngOnInit(): void {
    this.title = this.data.title;
    this.fgDatosGrals = this.frmBuilder.group({
      idPresentacionProyecto: [null],
      idEmpresa: [{value: null}],
      nit: [{value: null, disabled: true}],
      matriculaComercio: [{value: null}],
      codigoMteps: [{value: null, disabled: true}],
      razonSocial: [{value: null, disabled: true}],
      nombreComercial: [{value: null}],
      actividadDeclarada: [{value: null, disabled: true}],
      direccion: [{value: null, disabled: true}],
      totalTrabajadores: [{value: null, disabled: true}],
      representanteLegal: [{value: null, disabled: true}],
      proyectoEjecucion: [{value: false, disabled: true}],
      sucursalReparticionId: [{value: null, disabled: true}, [Validators.required]],
      nombreProyecto: [{value: null, disabled: true}],
      tieneSubcontratados: [{value: false, disabled: true}],
      cantidadMasculino: [{value: null, disabled: true}],
      cantidadFemenino: [{value: null, disabled: true}],
      cantidadTrabajadores: [{value: 0}],
      empresasSubcontratadas: [{value: null, disabled: true}],
      profesionalId: [null],
      origenOvt: [false],
      observaciones: [null],
      transaccion: [null],
      estado: ['INICIAL'],
      login: [sessionStorage.getItem('nroreg')]

    });
    this.srvObtienePsstPorNitEstado('ENVIADO');
    // this.validaActivacionCuenta();
  }
  public onNoClick(res: any = null): void {
    this.dialogRef.close(res);
  }

  public pryEjecucion(e: boolean): void {
    if (e) {
      this.fgDatosGrals.controls.nombreProyecto.enable();
      this.fgDatosGrals.controls.tieneSubcontratados.enable();
      this.fgDatosGrals.controls.empresasSubcontratadas.enable();
      this.fgDatosGrals.controls.sucursalReparticionId.disable();
    } else {
      this.fgDatosGrals.controls.nombreProyecto.disable();
      this.fgDatosGrals.controls.tieneSubcontratados.disable();
      this.fgDatosGrals.controls.empresasSubcontratadas.disable();
      this.fgDatosGrals.controls.sucursalReparticionId.enable();
    }
  }

  public srvObtienePsstPorNitEstado(pEstado: string): void {
    // @ts-ignore
    this.srvPresentaciones.wsPsstPorNitEstado(sessionStorage.getItem('login'), pEstado, sessionStorage.getItem('ovt')).subscribe(res => {
      if (res.correcto) {
        let pry: string | any = sessionStorage.getItem('pry');
        this.data.res = (res.datoAdicional.filter((x: { idPresentacionProyecto: number; }) => x.idPresentacionProyecto === parseInt(pry,10)))[0];
        if (this.data.res) {
          if (this.data.res.tieneSubcontratados) {
            let str = (this.data.res.empresasSubcontratadas).split(',');
            this.data.res.empresasSubcontratadas = str;
          }
          this.fgDatosGrals.patchValue(this.data.res);
          this.fgDatosGrals.controls.nombreProyecto.disable();
          this.fgDatosGrals.controls.sucursalReparticionId.disable();
          this.fgDatosGrals.controls.tieneSubcontratados.disable();
          this.fgDatosGrals.controls.empresasSubcontratadas.disable();
          this.fgDatosGrals.controls.estado.setValue(this.data.res.estado);
          this.notification.create('success', 'Acceso correcto!', `Bienvenido a la Plataforma de Presentación de Programas de Seguridad y Salud en el Trabajo - PSST`);
          this.srvDatosEmpresaPorNit();
        }else{
          this.onNoClick({correcto: false, notificacion: `No se encontraron datos de la presentación PSST asociado al NIT: ${sessionStorage.getItem('login')}`});
          // this.notification.create('error', 'Acceso Denegado!', `No se encontraron datos de la presentación PSST asociado al NIT: ${sessionStorage.getItem('login')}`);
          sessionStorage.clear();
        }
      } else {
        sessionStorage.clear();
        this.onNoClick({correcto: false, notificacion: `La solicitud ya no está vigente, solicite a la empresa el reenvió del PSST.`});
        // this.notification.create('error', 'Acceso Denegado!', `La solicitud ya no está vigente, solicite a la empresa el reenvió del PSST.`);
      }
    },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
        sessionStorage.clear();
      });
  }

  public srvDatosEmpresaPorNit(): void {
    // @ts-ignore
    this.srvPresentaciones.datosEmpresaPorNit(sessionStorage.getItem('login'), sessionStorage.getItem('ovt')).subscribe(res => {
        if (res.correcto) {
          this.fgDatosGrals.patchValue(res.datoAdicional[0]);
          this.fgDatosGrals.controls.totalTrabajadores.setValue(res.datoAdicional[0].cantidadTrabajadores);
          this.lstSucursales = res.datoAdicional[0].sucursales;
          if (this.data.transaccion == 'EDITAR') {
            this.fgDatosGrals.controls.sucursalReparticionId.setValue(this.data.res.sucursalReparticionId);
          }
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }

  public srvConfirmarPrfSyso(pTrans: string):void{
    const dlgConfirmacion = this.dialog.open(MdValidarSysoComponent, {
      panelClass: 'dlgModal',
      width: '450px',
      data: `Está seguro de la presentación PSST?`
    });
    dlgConfirmacion.afterClosed().subscribe(confirmado => {
      if (confirmado) {
        // this.onChangesTab(this.tabSelected);
        // this.notification.create('success', 'Correcto', res.notificacion);
        this.srvGestionPresentacionesPsst(pTrans);
      }
    });

  }

  public srvGestionPresentacionesPsst(pTrans: string = ''): void {
    const dlgConfirmacion = this.dialog.open(DlgConfirmComponent, {
      panelClass: 'dlgModal',
      width: '330px',
      data: `Está seguro de ${pTrans} la presentación PSST?`
    });
    dlgConfirmacion.afterClosed().subscribe(confirmado => {
      if (confirmado) {
        this.fgDatosGrals.controls.transaccion.setValue(pTrans);
        this.srvPresentaciones.wsGestionPresentacionesPsst(this.fgDatosGrals.value).subscribe(res => {
            if (res.correcto) {
              this.onNoClick(res);
            } else {
              this.notification.create('error', 'Error', res.notificacion);
            }
          },
          err => {
            // @ts-ignore
            this.notification.create('error', 'Error', message[err.status]);
          });
      }
    });
  }
}
