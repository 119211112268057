import { Injectable } from '@angular/core';
import {Observable, throwError} from "rxjs";
import {ResultInterface} from "../../models/auth/result-interface";
import {endPoint} from "../../../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {ServiceExceptionService} from "../service-exception.service";

@Injectable({
  providedIn: 'root'
})
export class OvtService extends ServiceExceptionService {

  constructor(private http: HttpClient) {
    super();
  }
  public verificaRegistroOVT(nit: string, matricula: string = '', razonSocial: string = ''): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/ovt/empresa?nit=${nit}&matricula=${matricula}&razon_social=${razonSocial}`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
