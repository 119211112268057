import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "../../core/services/auth/login.service";
import {Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {ImpuestosService} from "../../core/services/sin/impuestos.service";
import {NzMessageService} from "ng-zorro-antd/message"
import {message} from "../../../environments/const.component";
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzModalService} from "ng-zorro-antd/modal";
import {OvtService} from "../../core/services/ovt/ovt.service";
import {DlgActivarCuentaComponent} from "./dlg-activar-cuenta/dlg-activar-cuenta.component";
import {MatDialog} from "@angular/material/dialog";
import {UsuariosService} from "../../core/services/admin/usuarios.service";

@Component({
  selector: 'app-login-psst',
  templateUrl: './login-psst.component.html',
  styleUrls: ['./login-psst.component.scss'],
  providers: [MessageService]
})
export class LoginPsstComponent implements OnInit {
  public frmAuthLogin: FormGroup | any;
  public passwordVisible = false;
  public password?: string;
  public loading = false;
  public errStatusSin = message[510];
  public ifStatusSin: boolean = false;
  public pActCta: boolean = false;

  constructor(private srvAuth: LoginService,
              private route: Router,
              private notification: NzNotificationService,
              private wsSin: ImpuestosService,
              private wsOVT: OvtService,
              private srvUsr: UsuariosService,
              private msg: NzMessageService,
              private modal: NzModalService,
              public dlg: MatDialog) {
  }

  ngOnInit(): void {
    this.wsStatusSIN();
    this.info();
    this.frmAuthLogin = new FormGroup({
      // nit: new FormControl(null, [Validators.required]),
      nit: new FormControl('319020026', [Validators.required]),
      usuario: new FormControl(null, [Validators.required]),
      clave: new FormControl(null, [Validators.required])

    });
    //this.pActCta = sessionStorage.getItem('usr')? true : false;
    if(sessionStorage.getItem('acct')){
      this.gestionCuenta();
    }
  }
  info(): void {
    this.modal.info({
      nzTitle: 'Notificación',
      nzCentered: true,
      nzClosable: false,
      nzContent: '<p style="text-align: justify">Los canales de atención para consultas ' +
        'sobre el uso o problemas técnicos con la plataforma de Presentación de Programas de Seguridad y Salud en el Trabajo- PSST del Ministerio de Trabajo,' +
        ' Empleo y Previsión Social - MTEPS son los siguientes:</p>' +
        '<ul><li> Atención presencial en todas las oficinas dependientes del MTEPS. </li>' +
        '<li> Atención vía telefónica a través del número telefónico 2-408606.</li>' +
        '<li> Atención vía correo electrónico a través de la cuenta <a href="mailto:psst.soporte@mintrabajo.gob.bo">psst.soporte@mintrabajo.gob.bo</a></li></ul>',
      nzOkText: 'Aceptar',
      nzOnOk: () => console.log('Aceptar')
    });
  }

  public mostrarMsg(type: string, msg: any): void {
    this.msg.create(type, `${msg}`);
  }

  public validarForm(): void {
    if (this.frmAuthLogin.valid) {
      this.authLogin();
    } else {
      Object.values(this.frmAuthLogin.controls).forEach((control: any) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  };

  public authLogin(): void {
    this.loading = true;
    this.srvAuth.authLoginEmpresa(this.frmAuthLogin.value).subscribe(res => {
        this.loading = false;
        if (res.correcto) {
          this.validaActivacionCuenta();
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[505]);
      });
  }

  public validaActivacionCuenta(): void {
    this.srvAuth.wsActivacionCuenta(this.frmAuthLogin.controls.nit.value).subscribe(res => {
        if (res.correcto && res.datoAdicional[0].estado) {
          this.loading = false;
          this.route.navigate(['psst/dashboard']);
        } else {
          if(res.datoAdicional[0].estadoReg && !res.datoAdicional[0].estado){
            this.notification.create('warning', 'Activación pendiente!', `Ya existe un registro
                asociado al NIT: ${this.frmAuthLogin.controls.nit.value}, puede solicitar el re-envío de la solicitud
                de activación ingresando al siguiente enlace <a href="">Activar cuenta</a></p>`);
          }else{
            this.activarCuenta();
          }
        }
      },
      err => {
        this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }

  public verificaRegistroEmpresa(): void {
    this.wsOVT.verificaRegistroOVT(this.frmAuthLogin.controls.nit.value).subscribe(res => {
        if (res.correcto && res.datoAdicional.length>0) {
          this.activarCuenta();
        } else {
          this.notification.create('warning', 'Atención!', 'No cuenta con ROE en la OVT!, para realizar su registro ingrese al siguiente enlace <a href="https://ovt.mintrabajo.gob.bo/#/loginRoe">https://ovt.mintrabajo.gob.bo/#/loginRoe</a>');
        }
      },
      err => {
        this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }

  public activarCuenta(): void {
    const pModal = {title: 'ACTIVAR CUENTA', nit: this.frmAuthLogin.controls.nit.value};
    const dlgActCta = this.dlg.open(DlgActivarCuentaComponent, {
      panelClass: 'dlgModal',
      disableClose: true,
      data: pModal
    });
    dlgActCta.afterClosed().subscribe(res => {
      if (res.correcto) {
        // @ts-ignore
        this.notification.create('success', 'Correcto!', res.notificacion);
      }
    });
  }

  public wsStatusSIN(): void {
    const id = this.msg.loading('Verificando disponibilidad del SIN..', {nzDuration: 0}).messageId;
    this.wsSin.wsStatusSin().subscribe(res => {
        this.msg.remove(id);
        if (res.correcto) {
          this.mostrarMsg('success', res.notificacion);
        } else {
          this.ifStatusSin = true;
        }
      },
      err => {
        this.msg.remove(id);
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
        this.ifStatusSin = true;
      });
  }

  public gestionCuenta(): void {
    const pData = {
      nit: sessionStorage.getItem('login'),
      estado: 'ACTIVO',
      transaccion: 'EDITAR',
      login: sessionStorage.getItem('login')
    }
    this.srvUsr.admUsuariosPsst(pData).subscribe(res => {
        if (res.correcto) {
          this.notification.create('success', 'Cuenta Activada!', `La cuenta asociada al NIT: ${sessionStorage.getItem('login')}, se ACTIVO correctamente`);
        } else {
          this.loading = false;
          this.notification.create('error', 'Error', res.notificacion);
        }
        sessionStorage.clear();
      },
      err => {
        sessionStorage.clear();
        this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }

}
