import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {ProgressBarModule} from 'primeng/progressbar';
import {ToastModule} from 'primeng/toast';
import {InputTextModule} from 'primeng/inputtext';
import {CalendarModule} from 'primeng/calendar';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {PanelMenuModule} from 'primeng/panelmenu';
import {CardModule,} from 'primeng/card';
import {MenuItem} from 'primeng/api';
import {PanelModule} from 'primeng/panel';
import {RippleModule} from 'primeng/ripple';
import {HighchartsChartModule} from 'highcharts-angular';
import {InputMaskModule} from 'primeng/inputmask';
import {SplitterModule} from "primeng/splitter";
import {ChipsModule} from "primeng/chips";
import {SkeletonModule} from "primeng/skeleton";
import {InplaceModule} from "primeng/inplace";
import {EditorModule} from "primeng/editor";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    PanelModule,
    PanelMenuModule,
    ToastModule,
    RippleModule,
    CardModule,
    InputTextModule,
    ProgressBarModule,
    HttpClientModule,
    FormsModule,
    HighchartsChartModule,
    InputMaskModule,
    SplitterModule,
    ChipsModule,
    SkeletonModule,
    InplaceModule,
    EditorModule,
  ],
})
export class PrimeModule {
}
