<div class="modal-theme">
  <mat-toolbar color="primary">
    <span>{{title}}</span>
    <span class="toolbar-spacer-custom"></span>
    <a>
      <i nz-icon nzType="close" nzTheme="outline" (click)="onNoClick({correcto: false})" style="color:silver"></i>
    </a>
  </mat-toolbar>
  <div mat-dialog-content>
    <mat-card class="content-tab mat-elevation-z0">
      <mat-card-content>
        <form nz-form [nzLayout]="'vertical'" [formGroup]="fgRol">
          <nz-form-item>
            <nz-form-label>Descripción del Rol</nz-form-label>
            <nz-form-control nzErrorTip="Ingrese la descripción del Rol!">
              <input nz-input formControlName="descripcionRol" placeholder="Ingrese la descripción del Rol"/>
            </nz-form-control>
          </nz-form-item>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-divider></mat-divider>
  <div class="flex justify-content-end col-12">
    <button nz-button nzType="default" (click)="onNoClick({correcto: false})">
      <span>Cancelar</span>
    </button>&nbsp;
    <button nz-button nzType="primary" (click)="gestionRol()">
      <span>Aceptar</span>
    </button>
  </div>
</div>
