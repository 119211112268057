<div class="modal-theme">
  <mat-toolbar color="primary">
    <span>{{title}}</span>
    <span class="toolbar-spacer-custom"></span>
    <a>
      <i nz-icon nzType="close" nzTheme="outline" (click)="onNoClick({correcto: false})" style="color:silver"></i>
    </a>
  </mat-toolbar>
  <div mat-dialog-content>
    <mat-card class="content-tab mat-elevation-z0">
      <mat-card-content>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-divider></mat-divider>
  <div class="flex p-jc-end col-12">
    <button nz-button nzType="default" (click)="onNoClick()">
      <span>Cancelar</span>
    </button>&nbsp;
    <button nz-button nzType="primary" (click)="onNoClick()">
      <span>Aceptar</span>
    </button>
  </div>
</div>
