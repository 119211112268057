import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from './material/material.module';
import {PrimeModule} from './prime/prime.module';
import {DlgConfirmComponent} from './dlg-confirm/dlg-confirm.component';
import {DatepickerComponent, DatepickerMmYyyyComponent} from './datepicker/datepicker.component';
import {DlgFrmChangePassComponent } from './dlg-frm-change-pass/dlg-frm-change-pass.component';
import {AntModule} from "./ant/ant.module";
import { DlgRevertirComponent } from './dlg-revertir/dlg-revertir.component';
import { ActivarCuentaComponent } from './guards/services/activar-cuenta/activar-cuenta.component';
import { DlgUploadComponent } from './dlg-upload/dlg-upload.component';


@NgModule({
    declarations: [DlgConfirmComponent, DatepickerComponent, DatepickerMmYyyyComponent, DlgFrmChangePassComponent, DlgRevertirComponent, ActivarCuentaComponent, DlgUploadComponent],
    imports: [
        CommonModule,
        MaterialModule,
        PrimeModule,
        AntModule
    ],
    exports: [
        MaterialModule,
        PrimeModule,
        DatepickerComponent,
        DatepickerMmYyyyComponent,
        AntModule
    ]
})
export class SharedModule {
}
