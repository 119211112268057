import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LoginService} from "../../core/services/auth/login.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {message} from "../../../environments/const.component";
import { filter } from 'rxjs/operators';
import { Observable, Observer } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import {PresentacionesPsstService} from "../../core/services/psst/presentaciones-psst.service";

@Component({
  selector: 'app-dlg-upload',
  templateUrl: './dlg-upload.component.html',
  styleUrls: ['./dlg-upload.component.css']
})
export class DlgUploadComponent implements OnInit {
  public fgDatosSyso: FormGroup | any;
  public uploading = false;
  public fileList: NzUploadFile[] = [];
  public size: number | null = 0;
  public formData = new FormData();
  constructor(
    public dialogRef: MatDialogRef<DlgUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string,
    private frmBuilder: FormBuilder,
    private srvAuth: LoginService,
    private notification: NzNotificationService,
    private http: HttpClient, private msg: NzMessageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private srvPresentaciones: PresentacionesPsstService) {
  }

  public onNoClick(res: any = null): void {
    this.dialogRef.close(res);
  }

  ngOnInit(): void {
    this.size = this.data.res.pesoMaximo;
    this.fgDatosSyso = this.frmBuilder.group({
      numeroRegistro: [{value: null, disabled: false}, [Validators.required]],
      nroDocumento: [{value: null, disabled: false}, [Validators.required]],
    });
    this.data.res.estado='INICIAL';
    this.data.res.transaccion= 'ADICIONAR';
    this.data.res.login=sessionStorage.getItem('login');
  }

  public validaActivacionCuenta(pTrans: string = ''): void {
    // let pLogin: string | any = sessionStorage.getItem('nroreg');

    this.srvAuth.wsActivacionCuenta(this.fgDatosSyso.controls.numeroRegistro.value, true).subscribe(res => {
        if (res.correcto && res.datoAdicional[0].estado) {
          this.onNoClick(res);
        } else {
          this.notification.create('error', 'Error', `Los datos ingresados no corresponden a un profesional SYSO vigente.`);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }

  public beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): boolean => {
    this.formData.delete('fichero');
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
    if (!isJpgOrPng) {
      this.msg.error('Solo puede subir archivo PDF o imagenes!');
      return false;
    }
    // @ts-ignore
    const isLt2M = file.size <= (this.size * 1024);
    if (!isLt2M) {
      // @ts-ignore
      this.msg.error(`El archivo debe tener menos de ${this.size / 1024} MB!`);
      return false;
    }
    this.data.res.tipoMime = file.type;
    this.data.res.nombreExtension = file.name;
    // @ts-ignore
    this.data.res.tamanoArchivo = (file.size);
    this.fileList = [file];
    // @ts-ignore
    this.formData.append('fichero', file);
    return false;
  };

  public removeFile = (file: NzUploadFile): boolean => {
    this.fileList = [];
    return false;
  };

  public handleUpload(): void {

    this.formData.append("data", JSON.stringify(this.data.res));
    this.uploading = true;
    this.srvPresentaciones.wsUpload(this.formData).subscribe(res => {
        this.uploading = false;
        if (res.correcto) {
          this.onNoClick(res)
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        this.uploading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }
}
