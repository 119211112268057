import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {ResultInterface} from '../../core/models/auth/result-interface';
import {LoginService} from '../../core/services/auth/login.service';

@Component({
  selector: 'app-dlg-frm-change-pass',
  templateUrl: './dlg-frm-change-pass.component.html',
  styleUrls: ['./dlg-frm-change-pass.component.scss'],
  providers: [MessageService]
})
export class DlgFrmChangePassComponent implements OnInit {

  public title = null;
  public fgObtDatosPass: FormGroup | any;
  name = 'Angular 5';

  constructor(
    public dialogRef: MatDialogRef<DlgFrmChangePassComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    // tslint:disable-next-line:variable-name
    private _formBuilder: FormBuilder,
    private messageService: MessageService,
    private srvLogin: LoginService) {
  }
  public onNoClick(refClose: ResultInterface): void {
    this.dialogRef.close(refClose);
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.fgObtDatosPass = this._formBuilder.group({
      passwordActual: ['', Validators.required],
      passwordNuevo1: ['', Validators.required],
      passwordNuevo2: ['', Validators.required],
      login: [{value: sessionStorage.getItem('login'), disabled: false}]
    });
  }

  public operacion(op: string, param = null, id = null): void {
    // @ts-ignore
    this[op](param, id);
  }

  cambiarPass(close: boolean = false): void {
    this.srvLogin.changePass(this.fgObtDatosPass.value).subscribe((res => {
      this.onNoClick(res);
    }));
  }
}
