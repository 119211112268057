<div style="padding: 5px">
  <nz-breadcrumb style="margin: 10px">
    <nz-breadcrumb-item>
      <i nz-icon nzType="home"></i>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a routerLink="/psst/dashboard" >
        <span>Mantenimiento y Configuración</span>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>Usuarios</nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-card [nzBordered]="false" nzTitle="Lista de Usuarios">
    <div class="p-shadow-1">
      <p-table #dtUsuarios [value]="lstUsuarios" styleClass="p-datatable-sm" [(selection)]="selectedRow"
               dataKey="idUsuario"
               [rows]="5"
               [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[5,10,25,50]"
               [loading]="loading"
               [paginator]="true"
               currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} entradas"
               [globalFilterFields]="['usuario','nombreCompleto','unidadArea','puesto','estado']">
        <ng-template pTemplate="caption">
          <div class="flex">
            <span class="p-input-icon-left ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dtUsuarios.filterGlobal($any($event.target).value, 'contains')"
                             placeholder="Buscar..."/>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Usuario</th>
            <th>Nombre Completo</th>
            <th>Unidad/Área</th>
            <th>Cargo</th>
            <th>Estado</th>
            <th style="width:5rem">Acción</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-usuario>
          <tr [pSelectableRow]="usuario">
            <td>{{usuario.usuario}}</td>
            <td>{{usuario.nombreCompleto}}</td>
            <td>{{usuario.unidadArea}}</td>
            <td>{{usuario.puesto}}</td>
            <td>{{usuario.estado}}</td>
            <td style="text-align:center">
              <button *ngIf="usuario.estado == 'INACTIVO'" pButton pRipple type="button" icon="pi pi-check"
                      class="p-button-rounded p-button-text p-button-success p-mr-2"
                      nz-popconfirm nzPopconfirmTitle="HABILITAR el usuario seleccionado?"
                      (nzOnConfirm)="pHabilitaUsr(usuario)" nzOkText="Aceptar" nzCancelText="Cancelar" [matTooltip]="'Habilitar usuario'"></button>
              <button *ngIf="usuario.estado == 'ACTIVO'" pButton pRipple type="button" icon="pi pi-power-off"
                      class="p-button-rounded p-button-text p-button-danger"
                      nz-popconfirm nzPopconfirmTitle="DESHABILITAR el usuario seleccionado?"
                      (nzOnConfirm)="gestionWorkflow(usuario)" nzOkText="Aceptar" nzCancelText="Cancelar" [matTooltip]="'Deshabilitar usuario'"></button>
              <button *ngIf="usuario.estado == 'PENDIENTE'" pButton pRipple type="button" icon="pi pi-reply"
                      class="p-button-rounded p-button-text p-button-warning"
                      nz-popconfirm nzPopconfirmTitle="RE-ENVIAR solicitud de activación?"
                      (nzOnConfirm)="emailActivarCuenta(usuario)" nzOkText="Aceptar" nzCancelText="Cancelar" [matTooltip]="'Reenviar solicitud'"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </nz-card>
</div>
