import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {message} from "../../../../environments/const.component";
import {LoginService} from "../../../core/services/auth/login.service";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-md-validar-syso',
  templateUrl: './md-validar-syso.component.html',
  styleUrls: ['./md-validar-syso.component.css']
})
export class MdValidarSysoComponent implements OnInit {
  public fgDatosSyso: FormGroup | any;

  constructor(
    public dialogRef: MatDialogRef<MdValidarSysoComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string,
    private frmBuilder: FormBuilder,
    private srvAuth: LoginService,
    private notification: NzNotificationService) {
  }

  public onNoClick(res: any = null): void {
    this.dialogRef.close(res);
  }

  ngOnInit(): void {
    this.fgDatosSyso = this.frmBuilder.group({
      numeroRegistro: [{value: null, disabled: false}, [Validators.required]],
      nroDocumento: [{value: null, disabled: false}, [Validators.required]],
    });
  }

  public validaActivacionCuenta(pTrans: string = ''): void {
    // let pLogin: string | any = sessionStorage.getItem('nroreg');

    this.srvAuth.wsActivacionCuenta(this.fgDatosSyso.controls.numeroRegistro.value, true).subscribe(res => {
        if (res.correcto && res.datoAdicional[0].estado) {
          this.onNoClick(res);
        } else {
          this.notification.create('error', 'Error', `Los datos ingresados no corresponden a un profesional SYSO vigente.`);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }
}
