import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServiceExceptionService} from "../service-exception.service";
import {Observable, throwError} from "rxjs";
import {ResultInterface} from "../../models/auth/result-interface";
import {endPoint} from "../../../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import {httpOptions} from "../../../../environments/const.component";

@Injectable({
  providedIn: 'root'
})
export class SolicitudesService extends ServiceExceptionService {

  constructor(private http: HttpClient) {
    super();
  }
  public srvEstipendios(params: any): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/pasajesviaticos/tipoviaje?login=${params.login}`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public srvDestinosNivel(params: any): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/pasajesviaticos/destinosnivel?p_est_nivel=${params.estiNivel}`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public srvGestionSolicitudesPsjViaticos(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/pasajesviaticos/gestionsolpsjvtc`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public srvGestionDestinos(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/pasajesviaticos/gestiondestinos`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public srvSolicitudesPsjVcs(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/pasajesviaticos/solicitudespsjvco`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public srvCalculoDias(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/pasajesviaticos/calculodias`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public srvGeneraCiteSigec(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`https://servicios.mteps.gob.bo/mteps-desa/sigec/generaDoc`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public wsDownload(pFileName: string) {
    return this.http.get(`${endPoint.cnx.url}/pasajesviaticos/imprimirHr?code=${pFileName}`, {responseType: 'blob'});
  }

}
