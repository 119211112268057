import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import { AdminComponent } from './admin/admin.component';
import { UsuariosComponent } from './admin/usuarios/usuarios.component';
import { PerfilesComponent } from './admin/perfiles/perfiles.component';
import { RolesComponent } from './admin/roles/roles.component';
import { ParametrosComponent } from './admin/parametros/parametros.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DlgActivarUsuarioComponent } from './admin/usuarios/dlg-activar-usuario/dlg-activar-usuario.component';
import { DlgNuevoUsuarioComponent } from './admin/usuarios/dlg-nuevo-usuario/dlg-nuevo-usuario.component';
import { DlgRegistrarPerfilComponent } from './admin/perfiles/dlg-registrar-perfil/dlg-registrar-perfil.component';
import { DlgRegistrarDocumentoComponent } from './admin/parametros/dlg-registrar-documento/dlg-registrar-documento.component';
import { DlgRegistrarRolComponent } from './admin/roles/dlg-registrar-rol/dlg-registrar-rol.component';
import { RepSolicitudesComponent } from './dashboard/rep-solicitudes/rep-solicitudes.component';
import { RepAlertasComponent } from './dashboard/rep-alertas/rep-alertas.component';
import {ModRegistroDestinosComponent} from './admin/parametros/mod-registro-destinos/mod-registro-destinos.component';

@NgModule({
  declarations: [
    AdminComponent,
    UsuariosComponent,
    PerfilesComponent,
    RolesComponent,
    ParametrosComponent,
    DashboardComponent,
    DlgActivarUsuarioComponent,
    DlgNuevoUsuarioComponent,
    DlgRegistrarPerfilComponent,
    DlgRegistrarDocumentoComponent,
    DlgRegistrarRolComponent,
    RepSolicitudesComponent,
    RepAlertasComponent,
    ModRegistroDestinosComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class IndexModule { }
