import { Injectable } from '@angular/core';
import {ServiceExceptionService} from "../service-exception.service";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {ResultInterface} from "../../models/auth/result-interface";
import {endPoint} from "../../../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import {httpOptions} from "../../../../environments/const.component";

@Injectable({
  providedIn: 'root'
})
export class UsuariosService extends ServiceExceptionService {

  constructor(private http: HttpClient) {
    super();
  }

  public lstUsuarios(login: string): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/psst/listausuarios`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public lstPerfiles(modulo: string): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/psst/listaperfiles?modulo=${modulo}`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public lstRoles(modulo: string, perfil: string): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/psst/listaroles?modulo=${modulo}&perfil=${perfil}`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public gestionRol(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/psst/gestionworkflow`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public admUsuariosPsst(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/psst/gestionworkflow`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public emailActCta(pData: any): Observable<ResultInterface> {
    return this.http.post<ResultInterface>(`${endPoint.cnx.url}/correo/emailActivarCtaPsst`, pData, httpOptions).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  public getPersonalVigente(): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/pasajesviaticos/personalvigente`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  public getUsuariosSigec(): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`https://servicios.mteps.gob.bo/mteps/sigec/usuariossigec`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

}
