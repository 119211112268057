<div class="modal-theme">
  <div mat-dialog-content>
    <mat-card class="content-tab mat-elevation-z0">
      <mat-card-content>
        <form [formGroup]="fgDatosSyso">
          <nz-alert
            nzType="info"
            nzMessage="Atención!"
            nzDescription="Para confirmar el PSST ingrese los siguientes datos:"
            nzShowIcon
          ></nz-alert>
          <br>
          <div class="p-grid">
            <mat-form-field class="p-col-12 p-md-6 p-lg-6">
              <mat-label>Nro. Registro</mat-label>
              <input matInput formControlName="numeroRegistro" type="number">
              <span nz-icon nzType="info-circle" nzTheme="outline" matSuffix nz-tooltip nzTooltipTitle="Ingrese el número de registro de Profesional SYSO."></span>
            </mat-form-field>
            <mat-form-field class="p-col-12 p-md-6 p-lg-6">
              <mat-label>Nro. Cedula de Identidad</mat-label>
              <input matInput formControlName="nroDocumento" type="number">
              <span nz-icon nzType="info-circle" nzTheme="outline" matSuffix nz-tooltip nzTooltipTitle="En caso de contar con Complemento debe separa el mismo con un guión Ejm. 12345678-1E."></span>
            </mat-form-field>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <div class="p-d-flex p-jc-end p-col-12">
      <button nz-button nzType="default" (click)="onNoClick({correcto: false})">
        <span>Cancelar</span>
      </button>&nbsp;
      <button nz-button nzType="primary" (click)="validaActivacionCuenta('CONFIRMAR')">
        <span>Aceptar</span>
      </button>
    </div>
  </div>
</div>
