import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../core/services/auth/login.service';
import {Router} from '@angular/router';
import {MessageService, PrimeNGConfig} from 'primeng/api';
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ImpuestosService} from "../../core/services/sin/impuestos.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {message} from "../../../environments/const.component";
import {UsuariosService} from "../../core/services/admin/usuarios.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  public frmAuthLogin: FormGroup | any;
  public passwordVisible = false;
  public password?: string;
  public loading = false;

  constructor(private srvAuth: LoginService,
              private route: Router,
              private notification: NzNotificationService,
              private wsSin: ImpuestosService,
              private srvUsr: UsuariosService,
              private msg: NzMessageService) {
  }

  ngOnInit(): void {
    this.frmAuthLogin = new FormGroup({
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });
    if(sessionStorage.getItem('acct')){
      this.gestionCuenta();
    }
  }

  public mostrarMsg(type: string, msg: any): void {
    this.msg.create(type, `${msg}`);
  }

  public validarForm(): void {
    if (this.frmAuthLogin.valid) {
      this.authLogin();
    } else {
      Object.values(this.frmAuthLogin.controls).forEach((control: any) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  };

  public authLogin(): void {
    this.loading = true;
    this.srvAuth.authLoginMteps(this.frmAuthLogin.value).subscribe(res => {
        this.loading = false;
        if (res.correcto) {
          this.validaActivacionCuenta();
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }

  public validaActivacionCuenta(): void {
    this.srvAuth.wsActivacionCuenta(this.frmAuthLogin.controls.username.value).subscribe(res => {
        if (res.correcto && res.datoAdicional[0].estado) {
          this.loading = false;
          this.route.navigate(['viajar/dashboard']);
        } else {
          if(res.datoAdicional[0].estadoReg && !res.datoAdicional[0].estado){
            this.notification.create('warning', 'Activación pendiente!', `Ya existe un registro
                asociado al usuario: ${this.frmAuthLogin.controls.username.value}, puede re-enviar la solicitud
                de activación ingresando al siguiente enlace <a href="">Activar cuenta</a></p>`);
          }else{
            this.notification.create('error', 'Error', message[403]);
          }
        }
      },
      err => {
        this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }

  public activarCuenta(): void {
    this.srvAuth.wsActivarCuenta(this.frmAuthLogin.value).subscribe(res => {
        if (res.correcto) {
          this.loading = false;
          this.notification.create('info', 'Activar cuenta', res.notificacion);
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }
  public gestionCuenta(): void {
    const pData = {
      nit: sessionStorage.getItem('login'),
      estado: 'ACTIVO',
      transaccion: 'EDITAR',
      login: sessionStorage.getItem('login')
    }
    this.srvUsr.admUsuariosPsst(pData).subscribe(res => {
        if (res.correcto) {
          this.notification.create('success', 'Cuenta Activada!', `La cuenta asociada al Usuario: ${sessionStorage.getItem('login')}, se ACTIVO correctamente`);
        } else {
          this.loading = false;
          this.notification.create('error', 'Error', res.notificacion);
        }
        sessionStorage.clear();
      },
      err => {
        sessionStorage.clear();
        this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }

}
