import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {MatDialog} from '@angular/material/dialog';
import {message} from '../../../../environments/const.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {SolicitudesService} from '../../../core/services/pasajes-viaticos/solicitudes.service';
import {ModRegistroDestinosComponent} from "./mod-registro-destinos/mod-registro-destinos.component";


@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.css']
})
export class ParametrosComponent implements OnInit {
  public loading = false;
  public cols: any[] | any;
  public rowsDataDest: any[] = [];
  public selectedRow: any;
  public isFilter = false;
  public optEstipendios: any[] = [];
  public idEstipendio: any;

  constructor(private modal: MatDialog,
              private notification: NzNotificationService,
              private cnf: NzModalService,
              private nzModal: NzModalService,
              private viewContainerRef: ViewContainerRef,
              private srv: SolicitudesService) {
  }

  ngOnInit(): void {
    this.cols = [
      {field: 'nro', header: 'N°'},
      {field: 'descripcion', header: 'Descripción'}
    ];
    this.prcOptionsEstipendios();
  }

  public onRowUnselect(event: any = null): void {
    /*    if (this.selectedIndexTab == 0) {
          this.dataTrs.estado = 'INICIAL';
          this.loadLstTransacciones(this.dataTrs);
        } else {
          this.lstTransacciones = [];
        }*/
  }

  public onRowSelect(event: any = null): void {
    /*    this.dataTrs.estado = this.selectedRow.estado;
        this.loadLstTransacciones(this.dataTrs);*/
  }

  public prcOptionsEstipendios(): void {
    this.srv.srvEstipendios({login: sessionStorage.getItem('login')}).subscribe(res => {
        if (res.correcto) {
          this.optEstipendios = res.datoAdicional;
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', 'err');
      });
  }

  public prcLstDestinos(idNivel: any): void {
    const pData = {
      estiNivel: idNivel
    };
    if (idNivel) {
      this.srv.srvDestinosNivel(pData).subscribe(res => {
          if (res.correcto) {
            this.rowsDataDest = res.datoAdicional;
          } else {
            this.notification.create('error', 'Error', res.notificacion);
          }
        },
        err => {
          // @ts-ignore
          this.notification.create('error', 'Error', 'err');
        });
    } else {
      this.rowsDataDest = [];
    }
  }

  public procedureRegSolicitudes(pTrns: string = 'ADICIONAR', row: any = null): void {
    if (!row) {
      row = {
        idDestino: row ? row.idDestino : null,
        estiNivel: this.idEstipendio,
        transaccion: pTrns,
        login: sessionStorage.getItem('login'),
        estado: row ? row.estado : 'INICIAL'
      };
    }
    const params = {
      title: '',
      content: row,
      edit: pTrns === 'EDITAR' ? true : false,
      transaccion: pTrns
    };
    const modal = this.nzModal.create({
      nzTitle: 'Destino',
      nzWidth: 300,
      nzContent: ModRegistroDestinosComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: params
    });
    modal.afterOpen.subscribe(() => {
    });
    modal.afterClose.subscribe(result => {
      if (result.correcto) {
        this.prcLstDestinos(this.idEstipendio);
        this.notification.create('success', 'Correcto!', result.notificacion);
      }
    });
  }

  public prcGestionDestinos(pTrns: string, pData: any): void {
      this.cnf.confirm({
        nzTitle: '<i>Atención!</i>',
        nzContent: `¿Desea ${pTrns} el registro?`,
        nzOnOk: () => {
          pData.transaccion = pTrns;
          pData.login = sessionStorage.getItem('login');
          this.srv.srvGestionDestinos(pData).subscribe(resp => {
              if (resp.correcto) {
                this.prcLstDestinos(this.idEstipendio);
              } else {
                this.notification.create('error', 'Error', resp.notificacion);
              }
            },
            err => {
              // @ts-ignore
              this.notification.create('error', 'Error', message[err.status]);
            });
        }
      });
    }
}
