import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from './login/login.component';
import {SharedModule} from '../shared/shared.module';
import { LoginPsstComponent } from './login-psst/login-psst.component';
import { DlgActivarCuentaComponent } from './login-psst/dlg-activar-cuenta/dlg-activar-cuenta.component';
import { LoginSysoComponent } from './login-syso/login-syso.component';
import { DlgConfirmarPsstComponent } from './login-syso/dlg-confirmar-psst/dlg-confirmar-psst.component';
import { MdValidarSysoComponent } from './login-syso/md-validar-syso/md-validar-syso.component';


@NgModule({
  declarations: [LoginComponent, LoginPsstComponent, DlgActivarCuentaComponent, LoginSysoComponent, DlgConfirmarPsstComponent, MdValidarSysoComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class AuthModule { }
