import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from '@angular/cdk/layout';
import {SharedModule} from './shared/shared.module';
import {IndexComponent} from './modules/index.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {InterceptorService} from './core/services/auth/interceptor.service';
import {PanelMenuModule} from 'primeng/panelmenu';
import {MatCardModule} from '@angular/material/card';
import {NZ_ICONS} from 'ng-zorro-antd/icon';
import { FormsModule } from '@angular/forms';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AccountBookFill, AlertFill, AlertOutline } from '@ant-design/icons-angular/icons';
import {NgProgressModule} from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import {AuthModule} from './auth/auth.module';
import {IndexModule} from './modules/index.module';
import {PasajesViaticosModule} from './modules/pasajes-viaticos/pasajes-viaticos.module';
import es from '@angular/common/locales/es';
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
registerLocaleData(es);
const icons: IconDefinition[] = [ AccountBookFill, AlertOutline, AlertFill ];

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    SharedModule,
    PanelMenuModule,
    AuthModule,
    IndexModule,
    PasajesViaticosModule,
    MatCardModule,
    FormsModule,
    HttpClientModule,
    NzIconModule.forRoot(icons),
    NgProgressModule.withConfig({
      trickleSpeed: 200,
      min: 20,
      meteor: false
    }),
    NgProgressHttpModule,
  ],
  exports: [
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    { provide: NZ_I18N, useValue: es_ES },
    { provide: NZ_ICONS, useValue: icons }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
