import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {apiext} from "../../../../environments/const.component";

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private router: Router// , private notification: NotificationService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem('token');
    let request = req;
    if(req.url == apiext.endpoint_cpt){
      request = req.clone({
        headers: new HttpHeaders({
          Authorization: 'JWT ' + apiext.token_ppte,
          'Content-Type': 'application/json'
        })
      });
    }else{
      if (token) {
        request = req.clone({
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token
          })
        });
      }
    }
    return next.handle(request).pipe(tap(() => {},
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
            return;
        }
      })
    );
  }
}
