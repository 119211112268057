<div class="card-container blue-container flex align-items-center justify-content-center" style="height: 90%">
  <div class="w-max h-max text-white font-bold flex align-items-center justify-content-center border-round">
    <form [formGroup]="frmAuthLogin" (ngSubmit)="validarForm()">
      <div style="max-width: 310px">
        <p-card>
          <ng-template pTemplate="header">
            <div class="flex align-items-center justify-content-center">
              <img src="../assets/LogoMteps.jpg" alt="Ministerio de Trabajo, Empleo y Previsión Social"
                   style="width: 30%">
            </div>
            <div class="flex align-items-center justify-content-center">
              <div class="flex-1 text-bluegray-500 font-bold text-center pt-4">Sistema de Gestión de Pasajes y Viáticos</div>
            </div>
          </ng-template>
          <nz-form-item>
            <nz-form-control nzErrorTip="Ingrese su usuario">
              <nz-input-group [nzPrefix]="pfxUsr">
                <input type="text" nz-input placeholder="Usuario" formControlName="username"/>
              </nz-input-group>
              <ng-template #pfxUsr><i nz-icon nzType="user" style="color: #2196F3;"></i></ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Ingrese su contraseña!">
              <nz-input-group [nzSuffix]="suffixTemplate" [nzPrefix]="pfxPwd">
                <input [type]="passwordVisible ? 'text' : 'password'" nz-input placeholder="Contraseña"
                       [(ngModel)]="password" formControlName="password"/>
              </nz-input-group>
              <ng-template #pfxPwd><i nz-icon nzType="lock" nzTheme="twotone"></i></ng-template>
              <ng-template #suffixTemplate>
                <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                   (click)="passwordVisible = !passwordVisible"></i>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <ng-template pTemplate="footer">
            <div class="flex align-items-center justify-content-end">
              <!--<p-button label="Acceder" [loading]="loading" (onClick)="validarForm()"></p-button>-->
              <button nz-button nzType="primary" nzSize="default" [nzLoading]="loading" type="submit">Acceder</button>
            </div>
            <br>
            <p style="color: #085879; text-align: justify; font-size: small"><strong>Nota.-</strong> Si no recibió el
              correo de activación de cuenta, puede solicitar el re-envío del mismo ingresando al siguiente enlace
              <a
                href="">Activar cuenta</a></p>
          </ng-template>
        </p-card>
      </div>
    </form>
  </div>
</div>
