<div class="modal-theme">
  <div mat-dialog-content>
    <mat-card class="content-tab mat-elevation-z0">
      <mat-card-content>
        <div class="p-grid">
          <div class="p-col-2">
            <div class="icons-list">
              <i nz-icon nzType="question-circle" [nzTheme]="'outline'"></i>
            </div>
          </div>
          <div class="p-col-10">
            <h2 style="font-weight: 400">Atención!</h2>
            {{message}}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <div class="p-d-flex p-jc-end p-col-12">
      <button nz-button nzType="default" (click)="onNoClick(false)">
        <span>Cancelar</span>
      </button>&nbsp;
      <button nz-button nzType="primary" (click)="onNoClick(true)">
        <span>Aceptar</span>
      </button>
    </div>
  </div>
</div>
