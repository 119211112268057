<div class="modal-theme">
  <mat-toolbar color="primary">
    <span>{{title}}</span>
    <span class="toolbar-spacer-custom"></span>
    <a>
      <i nz-icon nzType="close" nzTheme="outline" (click)="onNoClick({correcto: false})" style="color:silver"></i>
    </a>
  </mat-toolbar>
  <div mat-dialog-content>
    <mat-card class="content-tab mat-elevation-z0">
      <mat-card-content>
        <nz-divider nzPlain nzText="Usuario" nzOrientation="left"></nz-divider>
        <p><strong>Usuario: </strong>{{usuario}}</p>
        <p><strong>Cargo: </strong>{{cargo}}</p>
        <p><strong>Unidad Org.: </strong>{{unidadArea}}</p>
        <nz-divider nzPlain nzText="Perfiles asignados" nzOrientation="left"></nz-divider>
        <div class="p-grid">
          <div class="p-col">
            <nz-transfer
              [nzDataSource]="listPerfiles"
              [nzTitles]="['Por Asignar', 'Asignados']"
              [nzItemsUnit]="'Perfiles'"
              [nzItemUnit]="'Perfil'">
            </nz-transfer>
          </div>
        </div>
        <nz-divider nzPlain nzText="Nivel" nzOrientation="left"></nz-divider>
        <div class="p-grid">
          <div class="p-col">
            <nz-form-item>
              <nz-form-control nzErrorTip="Seleccione un trámite!">
                <nz-select [(ngModel)]="ambito" nzShowSearch nzAllowClear
                           nzPlaceHolder="Tipo"
                           nzSize="default">
                  <nz-option *ngFor="let option of lstAmbito" [nzLabel]="option.nombre"
                             [nzValue]="option.idClasificador"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-divider></mat-divider>
  <div class="flex justify-content-end col-12">
    <button nz-button nzType="default" (click)="onNoClick({correcto: false})">
      <span>Cancelar</span>
    </button>&nbsp;
    <button nz-button nzType="primary" (click)="gestionWorkflow()">
      <span>Aceptar</span>
    </button>
  </div>
</div>
