<div class="modal-theme">
  <div mat-dialog-content>
    <mat-card class="content-tab mat-elevation-z0">
      <mat-card-content>
        <form [formGroup]="fgDatosSyso">
          <nz-alert nzType="info" nzMessage="Se admite archivos tipo imagen o PDF, se sugiere subir archivos de hasta 5MB en tamaño." nzShowIcon></nz-alert>
          <br>
          <div class="p-grid">
            <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" class="p-col" [nzRemove]="removeFile">
              <button nz-button [disabled]="fileList.length === 1">
                <span nz-icon nzType="upload"></span>
                Seleccionar archivo
              </button>
            </nz-upload>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <div class="p-d-flex p-jc-end p-col-12">
      <button nz-button nzType="default" (click)="onNoClick({correcto: false})">
        <span>Cancelar</span>
      </button>&nbsp;
      <button
        nz-button
        [nzType]="'primary'"
        [nzLoading]="uploading"
        (click)="handleUpload()"
        [disabled]="fileList.length === 0">
        {{ uploading ? 'Subiendo' : 'Guardar' }}
      </button>
    </div>
  </div>
</div>
