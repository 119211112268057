<div>
  <div class="steps-content">
    <div *ngIf="current===0">
      <form [formGroup]="fgDatosGrals">
        <div class="grid">
          <mat-form-field class="col">
            <mat-label>Nombres y apellidos:</mat-label>
            <input matInput formControlName="nombreCompleto" type="text">
          </mat-form-field>
          <mat-form-field class="col">
            <mat-label>Cargo:</mat-label>
            <input matInput formControlName="cargo" type="text">
          </mat-form-field>
        </div>
        <div class="p-fluid grid formgrid">
          <div class="field col">
            <label for="lblA" class="text-sm">A:</label>
            <div id="lblA">
                  <nz-select formControlName="desRec" nzShowSearch nzAllowClear
                             nzPlaceHolder="Seleccione..."
                             nzSize="default"
                             [nzLoading]="loadUsers">
                    <nz-option *ngFor="let opt of optPersonal" [nzLabel]="opt.nombre+' | '+opt.cargo"
                               [nzValue]="opt.nombre+' | '+opt.cargo"></nz-option>
                  </nz-select>
            </div>
          </div>
        </div>
        <div class="p-fluid grid formgrid">
          <div class="field col">
            <label for="lblVia1" class="text-sm">VIA (s):</label>
            <div id="lblVia1">
              <nz-select formControlName="desVias" nzShowSearch nzAllowClear
                         nzPlaceHolder="Seleccione..."
                         nzSize="default" nzMode="multiple">
                <nz-option *ngFor="let opt of optPersonal" [nzLabel]="opt.nombre+' | '+opt.cargo"
                           [nzValue]="opt.nombre+' | '+opt.cargo"></nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col">
            <p-editor formControlName="desInforme" [style]="{'height':'100px'}"></p-editor>
          </div>
        </div>
        <br>
        <div class="grid">
          <div class="col-6">
            <span>¿Se le otorgó viáticos antes de realizar su viaje?</span>
          </div>
          <div class="col-6">
            <nz-form-item>
              <nz-form-control [nzSpan]="null">
                <nz-radio-group formControlName="desViaticosPrevio">
                  <label nz-radio [nzValue]="false" (click)="isViaticosPrevio = false">No</label>
                  <label nz-radio [nzValue]="true" (click)="isViaticosPrevio = true">Si</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="grid" *ngIf="false">
          <div class="col-6">
            <span>¿Anexa el formulario RC – IVA y notas fiscales para descargo?</span>
          </div>
          <div class="col-3">
            <nz-form-item>
              <nz-form-control [nzSpan]="null">
                <nz-radio-group formControlName="desAnexaRciva">
                  <label nz-radio [nzValue]="false" (click)="isAnexaRciva = false">No</label>
                  <label nz-radio [nzValue]="true" (click)="isAnexaRciva = true">Si</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-3">
            <div class="field col">
              <label for="lblMntIva" class="text-sm">Monto RC-IVA</label>
              <div id="lblMntIva">
                <nz-input-number
                  formControlName="desImporteFacturas"
                  [nzMin]="0"
                  [nzMax]="100000"
                  [nzFormatter]="formatterBol"
                  [nzParser]="parserBol"
                  [nzDisabled]="!isAnexaRciva"
                ></nz-input-number>
              </div>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col-6">
            <span>Monto para Reembolso (Otros gastos realizados durante la realización de la comisión)</span>
          </div>
          <div class="col-6">
            <nz-input-number
              formControlName="desReembolso"
              [nzMin]="0"
              [nzMax]="100000"
              [nzFormatter]="formatterBol"
              [nzParser]="parserBol"
              [nzDisabled]="false"
            ></nz-input-number>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div *nzModalFooter>
  <button nz-button nzType="primary" (click)="prcGestionSolicitudesPsjVts()" [nzLoading]="processing">
    <span>Aceptar</span>
  </button>
</div>
