import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LoginService} from '../../core/services/auth/login.service';
import {take, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckLoginGuard implements CanActivate {
  constructor(private authSvc: LoginService, private router: Router) {
  }

  canActivate(): Observable<boolean> {
    return this.authSvc.isLogged.pipe(
      take(1),
      map((isLogged: boolean) => {
        if (isLogged) {
          return isLogged;
        } else {
          this.router.navigate(['login']);
        }
        return isLogged;
      })
    );
  }
}
