<nz-result nzStatus="403" nzTitle="403" nzSubTitle="Lo sentimos, no está autorizado para acceder a esta página." *ngIf="err403">
  <div nz-result-extra>
    <button nz-button nzType="primary" (click)="srvSistemaPsst()">Ir al sistema PSST</button>
  </div>
</nz-result>

<nz-result
  nzStatus="success"
  nzTitle="¡Código de la transacción PSST: 2017182818828182881!"
  nzSubTitle="Salve en un lugar seguro el código proporcionado, el mismo será solicitado para atender cualquier inconveniente con esta presentación PSST."
  *ngIf="success"
>
  <div nz-result-extra>
    <button nz-button nzType="primary" (click)="srvSistemaPsst()">Ir al sistema PSST</button>
  </div>
</nz-result>

<nz-result nzStatus="warning" nzTitle="Hay algunos problemas con su operación."
  nzSubTitle="¡La Presentación PSST no puede ser obtenida, verifique que la misma no ha sido confirmada. También pude solicitar a la empresa se reenvié la solicitud!"
  *ngIf="error"
>
  <div nz-result-extra>
    <button nz-button nzType="primary" (click)="srvSistemaPsst()">Ir al sistema PSST</button>
  </div>
</nz-result>
