import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-confirm',
  templateUrl: './dlg-confirm.component.html',
  styleUrls: ['./dlg-confirm.component.css']
})
export class DlgConfirmComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DlgConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) {
  }

  onNoClick(refClose = false): void {
    this.dialogRef.close(refClose);
  }

  ngOnInit(): void {
  }

}
