import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {FORMATS_MMYYYY, MY_FORMATS} from '../../../environments/const.component';
import {FormGroup} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment, Moment} from 'moment';
import {MatDatepicker} from "@angular/material/datepicker";

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es-eu'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class DatepickerComponent {
  @Input() parentForm: FormGroup | any;
  @Input() fecMax: string | undefined;
  @Input() fecMin: string | undefined;
  @Input() placeHolder: string | undefined;
  @Input() frmCtrlName: string | undefined;
  @Input() dtrequired: boolean | any;
  @Input() visible = true;

  constructor(private adapter: DateAdapter<any>) {
  }
  // tslint:disable-next-line:typedef
  french() {
    this.adapter.setLocale('fr');
  }
}
@Component({
  selector: 'app-datepicker-mm-yyyy',
  templateUrl: './datepickermmyyyy.component.html',
  styleUrls: ['./datepicker.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-eu'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: FORMATS_MMYYYY},
  ],
})
export class DatepickerMmYyyyComponent {

  @Input() parentForm: FormGroup | any;
  @Input() fecMax: string | undefined;
  @Input() fecMin: string | undefined;
  @Input() placeHolder: string | undefined;
  @Input() frmCtrlName: string | undefined;
  @Input() dtrequired: boolean | any;
  @Input() visible: boolean = true;
  @Output() public onFormatMM: EventEmitter<any> = new EventEmitter();
  @Output() public onFormatYYYY: EventEmitter<any> = new EventEmitter();


  runYearHandler(normalizedYear: Moment) {
    this.onFormatYYYY.emit(normalizedYear);
  }

  runMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.onFormatMM.emit([normalizedMonth, datepicker]);
  }
}
