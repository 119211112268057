<div style="padding: 5px">
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <i nz-icon nzType="home"></i>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a routerLink="/viajar/pasajes-viaticos/solicitudes">
        <span>Mantenimiento y Configuración</span>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a routerLink="/viajar/pasajes-viaticos/solicitudes">
        <span>Parámetros</span>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>Destinos</nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-card [nzBordered]="false" nzTitle="CONFIGURACIÓN DE DESTINOS">
    <div class="p-fluid grid formgrid">
      <div class="field col">
        <label for="selectTipoViaje" class="text-sm">Ambito:</label>
        <div id="selectTipoViaje">
          <nz-select [(ngModel)]="idEstipendio" nzShowSearch nzAllowClear
                     nzPlaceHolder="Seleccione una opción..."
                     (ngModelChange)="prcLstDestinos($event)"
                     nzSize="default">
            <nz-option *ngFor="let opt of optEstipendios"
                       [nzLabel]="opt.destino  +' | '+  opt.estLugar"
                       [nzValue]="opt.estNivel"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>

    <p-table #dt
             dataKey="idDestino"
             [showCurrentPageReport]="true"
             [paginator]="true"
             [rows]="10"
             currentPageReportTemplate="Total registros {totalRecords}"
             [globalFilterFields]="['descripcion']"
             [columns]="cols"
             [value]="rowsDataDest"
             responsiveLayout="stack"
             styleClass="p-datatable-sm"
             selectionMode="single"
             [(selection)]="selectedRow">
      <ng-template pTemplate="caption">
        <div class="flex">
          <div class="col-12 m-1 p-0" *ngIf="isFilter">
            <nz-input-group [nzSuffix]="inputClearTpl" [nzPrefix]="prefixSearch">
              <input nz-input placeholder="Ingrese el texto para inciar la búsqueda..."
                     (input)="dt.filterGlobal($any($event.target).value, 'contains')"/>
            </nz-input-group>
            <ng-template #prefixSearch><span nzSize="small" nz-icon nzType="search"></span></ng-template>
            <ng-template #inputClearTpl>
              <span
                nz-icon
                class="ant-input-clear-icon"
                nzTheme="fill"
                nzType="close-circle"
                *ngIf="true"
                (click)="isFilter = !isFilter; prcLstDestinos(idEstipendio)"
              ></span>
            </ng-template>
          </div>
          <span class="p-input-icon-left ml-auto" *ngIf="!isFilter">
              <button mat-icon-button color="accent" matTooltip="Agregar Destino" (click)="procedureRegSolicitudes()" [disabled]="!idEstipendio">
                <mat-icon>add_box</mat-icon>
              </button>
              <button mat-icon-button color="accent" matTooltip="Filtrar" (click)="isFilter = !isFilter" [disabled]="!idEstipendio">
                <mat-icon>filter_list</mat-icon>
              </button>
              <button mat-icon-button color="accent" matTooltip="Actualizar" (click)="prcLstDestinos(idEstipendio)" [disabled]="!idEstipendio">
                <mat-icon>refresh</mat-icon>
              </button>
            </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
          <th style="width:10rem">Acciones</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            <span class="p-column-title font-bold">{{col.field}}</span>{{rowData[col.field]}}
          </td>
          <td>
            <span>
              <button pButton pRipple type="button" icon="pi pi-pencil" (click)="procedureRegSolicitudes('EDITAR', rowData)" class="p-button-rounded p-button-text" pTooltip="Editar"></button>
              <button pButton pRipple type="button" icon="pi pi-times" (click)="prcGestionDestinos('ANULAR', rowData)" class="p-button-rounded p-button-text p-button-danger" pTooltip="Anular"></button>
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody" let-columns="columns">
        <tr>
          <td *ngFor="let col of columns; let even = even">
            <p-skeleton></p-skeleton>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </nz-card>
</div>
