 <div class="modal-theme">
   <div mat-dialog-content>
<!--     <p>{{message}}</p>-->
     <nz-alert
       nzType="warning"
       nzMessage="Atención!"
       nzDescription="{{message}}"
       nzShowIcon
     ></nz-alert>
     <br />
     <textarea nz-input nzAutosize rows="4" [(ngModel)]="observacion" placeholder="Describa el detalle del motivo" required></textarea>
   </div>
   <div mat-dialog-actions>
     <div class="p-d-flex p-jc-end p-col-12">
       <button nz-button nzType="default" (click)="onNoClick(false)">
         <span>Cancelar</span>
       </button>&nbsp;
       <button nz-button nzType="primary" [disabled]="!observacion"
               (click)="onNoClick({correcto: true, datoAdicional: observacion})">
         <span>Aceptar</span>
       </button>
     </div>
   </div>
 </div>
