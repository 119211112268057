import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SolicitudesComponent } from './solicitudes/solicitudes.component';
import {SharedModule} from '../../shared/shared.module';
import { ModalRegSolicitudesComponent } from './solicitudes/modal-reg-solicitudes/modal-reg-solicitudes.component';
import { LiquidacionComponent } from './liquidacion/liquidacion.component';
import { ReliquidacionComponent } from './reliquidacion/reliquidacion.component';
import { DescargosComponent } from './descargos/descargos.component';
import { InformeComponent } from './descargos/informe/informe.component';



@NgModule({
  declarations: [
    SolicitudesComponent,
    ModalRegSolicitudesComponent,
    LiquidacionComponent,
    ReliquidacionComponent,
    DescargosComponent,
    InformeComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class PasajesViaticosModule { }
