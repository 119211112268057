import { Component, OnInit } from '@angular/core';
import {TransaccionesInterface} from "../../../core/models/transacciones/transacciones-interface";
import {UsuariosService} from "../../../core/services/admin/usuarios.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {MatDialog} from "@angular/material/dialog";
import {message} from "../../../../environments/const.component";
import {DlgRegistrarPerfilComponent} from "./dlg-registrar-perfil/dlg-registrar-perfil.component";

@Component({
  selector: 'app-perfiles',
  templateUrl: './perfiles.component.html',
  styleUrls: ['./perfiles.component.css']
})
export class PerfilesComponent implements OnInit {
  public lstPerfiles: any[] = [];
  public selectedRow: any;
  public lstTransacciones: TransaccionesInterface[] | any;
  public loading: boolean = true;
  constructor(private srvUsr: UsuariosService,
              private notification: NzNotificationService,
              public modal: MatDialog) { }

  ngOnInit(): void {
    this.pListarPerfiles();
  }
  public operacion(op: string, param: string): void {
    // @ts-ignore
    this[op](param);
  }
  public onRowUnselect(event: any = null): void {
    const data = {
      estado: 'INICIAL',
      username: sessionStorage.getItem('login'),
      tabla: '6'
    };
    // this.loadLstTransacciones(data);
  }

  public onRowSelect(event: any): void {
    const data: TransaccionesInterface = {
      estado: event.data.estado,
      username: sessionStorage.getItem('login'),
      tabla: '6'
    };
    // this.loadLstTransacciones(data);
  }

  public pRegistrarPfl(prm: string, row: any = null): void {
    const pData = {title: prm == 'ADD_PERFIL'? 'NUEVO PERFIL': 'EDITAR PERFIL', res: row, trns: prm};
    const cnf = {panelClass: 'dlgModal', disableClose: true, width: '400px', data: pData }
    const pModal = this.modal.open(DlgRegistrarPerfilComponent, cnf);
    pModal.afterClosed().subscribe(res => {
      if (res.correcto) {
        this.pListarPerfiles();
        this.notification.create('success', 'Correcto', res.notificacion);
      }
    });
  }

  public pListarPerfiles(): void {
    // @ts-ignore
    this.srvUsr.lstPerfiles('PSST').subscribe((res => {
      this.loading = false;
      if (res.correcto) {
        this.lstPerfiles = res.datoAdicional;
      } else {
        this.notification.create('error', 'Error', res.notificacion);
      }
    }));
  }

  public gestionWorkflow(row: any): void {
    const pData = {
      perfil: row.perfil,
      estado: 'INICIAL',
      transaccion: 'ELIMINAR_PERFIL',
      login: sessionStorage.getItem("login")
    };
    this.srvUsr.admUsuariosPsst(pData).subscribe(res => {
        if (res.correcto) {
          this.notification.create('success', 'Correcto', res.notificacion);
          this.pListarPerfiles();
        } else {
          //this.loading = false;
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        //this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }
}
