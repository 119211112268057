<div>
  <div class="grid">
    <mat-form-field class="col">
      <mat-label>Descripción...</mat-label>
      <textarea matInput [(ngModel)]="descDestino"></textarea>
    </mat-form-field>
  </div>
</div>
<div *nzModalFooter>
  <button nz-button nzType="default" (click)="destroyModal(null)">
    <span>Cancelar</span>
  </button>
  <button nz-button nzType="primary" (click)="prcGestionDestinos()">
    <span>Aceptar</span>
  </button>
</div>
