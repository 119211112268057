import {Component, Inject, OnInit} from '@angular/core';
import {TransferItem} from "ng-zorro-antd/transfer";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UsuariosService} from "../../../../core/services/admin/usuarios.service";
import {ClasificadorService} from "../../../../core/services/parametros/clasificador.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {message} from "../../../../../environments/const.component";
import {DocumentosService} from "../../../../core/services/parametros/documentos.service";

@Component({
  selector: 'app-dlg-registrar-documento',
  templateUrl: './dlg-registrar-documento.component.html',
  styleUrls: ['./dlg-registrar-documento.component.css']
})
export class DlgRegistrarDocumentoComponent implements OnInit {
  public title = null;
  public fgDoc: FormGroup | any;
  public isHorizontal: boolean = true;
  public listOfSelectedValue = null;

  constructor(public dialogRef: MatDialogRef<DlgRegistrarDocumentoComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private srvDoc: DocumentosService,
              private srvClasificador: ClasificadorService,
              private notification: NzNotificationService,
              private frmBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.fgDoc = this.frmBuilder.group({
      idTipoDocumento: [null],
      codigo: [null],
      nombre: [null, Validators.required],
      orden: [null, Validators.required],
      pesoMaximo: [null, Validators.required],
      estado: ['INICIAL'],
      transaccion: ['ADICIONAR'],
      login: [sessionStorage.getItem("login")]
    });
    if (this.data.res) {
      this.fgDoc.controls.idTipoDocumento.setValue(this.data.res.idTipoDocumento);
      this.fgDoc.controls.codigo.setValue(this.data.res.codigo);
      this.fgDoc.controls.nombre.setValue(this.data.res.nombre);
      this.fgDoc.controls.orden.setValue(this.data.res.orden);
      this.fgDoc.controls.pesoMaximo.setValue(this.data.res.pesoMaximo);
      this.fgDoc.controls.transaccion.setValue(this.data.trns);
    }
  }

  public onNoClick(res: any = null): void {
    this.dialogRef.close(res);
  }

  public gestionDocumento(): void {
    if (this.fgDoc.valid) {
      this.srvDoc.gestionDocumentos(this.fgDoc.value).subscribe(res => {
          if (res.correcto) {
            this.onNoClick(res);
          } else {
            //this.loading = false;
            this.notification.create('error', 'Error', res.notificacion);
          }
        },
        err => {
          //this.loading = false;
          // @ts-ignore
          this.notification.create('error', 'Error', message[err.status]);
        });
    } else {
      Object.values(this.fgDoc.controls).forEach((control: any) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

}
