import { Injectable } from '@angular/core';
import {ResultInterface} from '../models/auth/result-interface';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceExceptionService {

  constructor() { }
  // tslint:disable-next-line:typedef
  protected log(message: string) {
    if (message.length > 0) {
      console.log('log: ' + message);
    }
  }
  // tslint:disable-next-line:typedef
  protected handleError<T>(operation = 'operation', result: ResultInterface) {
    return (error: ErrorEvent): Observable<ResultInterface> => {
      result.notificacion = error.message;
      return of(result as ResultInterface);
    };
  }
}
