<div style="padding: 5px">
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <i nz-icon nzType="home"></i>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a routerLink="/viajar/pasajes-viaticos/solicitudes">
        <span>Pasajes y Viáticos</span>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>Mis solicitudes</nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-card [nzBordered]="false" nzTitle="MIS SOLICITUDES DE PASAJES Y VIÁTICOS">
    <p-table #dt
             dataKey="idSolicitud"
             [showCurrentPageReport]="true"
             [paginator]="true"
             [rows]="5"
             currentPageReportTemplate="Total registros {totalRecords}"
             [globalFilterFields]="['solNumero','solGestion','fechaCreacion','solObjetivo','destino','pvFechaHoraPartida','pvFechaHoraRetorno','estado']"
             [columns]="cols"
             [value]="rowsDataSol"
             responsiveLayout="stack"
             styleClass="p-datatable-sm"
             selectionMode="single"
             [(selection)]="selectedRow">
      <ng-template pTemplate="caption">
        <div class="flex">
          <div class="col-12 m-1 p-0" *ngIf="isFilter">
            <nz-input-group [nzSuffix]="inputClearTpl" [nzPrefix]="prefixSearch">
              <input nz-input placeholder="Ingrese el texto para inciar la busqueda..."
                     (input)="dt.filterGlobal($any($event.target).value, 'contains')"/>
            </nz-input-group>
            <ng-template #prefixSearch><span nzSize="small" nz-icon nzType="search"></span></ng-template>
            <ng-template #inputClearTpl>
              <span
                nz-icon
                class="ant-input-clear-icon"
                nzTheme="fill"
                nzType="close-circle"
                *ngIf="true"
                (click)="isFilter = !isFilter; prcSolicitudesPorUsrEstado()"
              ></span>
            </ng-template>
          </div>
          <span class="p-input-icon-left ml-auto" *ngIf="!isFilter">
              <button mat-icon-button color="accent" matTooltip="Agregar Solicitud" (click)="procedureRegSolicitudes()">
                <mat-icon>add_box</mat-icon>
              </button>
              <button mat-icon-button color="accent" matTooltip="Filtrar" (click)="isFilter = !isFilter">
                <mat-icon>filter_list</mat-icon>
              </button>
              <button mat-icon-button color="accent" matTooltip="Actualizar" (click)="prcSolicitudesPorUsrEstado()">
                <mat-icon>refresh</mat-icon>
              </button>
            </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
          <th style="width:10rem">Transacciones</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            <span class="p-column-title font-bold">{{col.field}}</span>{{rowData[col.field]}}
          </td>
          <td>
            <span class="p-column-title font-bold">Transacciones</span>
            <span *ngIf="rowData.estado === 'ENVIADO'">
              <button pButton pRipple type="button" icon="pi pi-file-pdf" (click)="prcGenerarFrmSolicitud(rowData)" class="p-button-rounded p-button-text p-button-success" pTooltip="Generar formulario"></button>
              <button pButton pRipple type="button" icon="pi pi-print" (click)="srvImprimirHR(rowData)" class="p-button-rounded p-button-text p-button-success" pTooltip="Imprimir Hoja de Ruta"></button>
              <button pButton pRipple type="button" icon="pi pi-undo" (click)="prcGestionSolicitudesPsjVts('REVERTIR', rowData)" class="p-button-rounded p-button-text" pTooltip="Cancelar envío"></button>
            </span>
            <span *ngIf="rowData.estado === 'ELABORADO'">
              <button pButton pRipple type="button" icon="pi pi-check" (click)="prcGestionSolicitudesPsjVts('ENVIAR', rowData)" class="p-button-rounded p-button-text p-button-success" pTooltip="Enviar"></button>
              <button pButton pRipple type="button" icon="pi pi-pencil" (click)="procedureRegSolicitudes('EDITAR', rowData)" class="p-button-rounded p-button-text" pTooltip="Editar"></button>
              <button pButton pRipple type="button" icon="pi pi-times" (click)="prcGestionSolicitudesPsjVts('ANULAR', rowData)" class="p-button-rounded p-button-text p-button-danger" pTooltip="Anular"></button>
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody" let-columns="columns">
        <tr>
          <td *ngFor="let col of columns; let even = even">
            <p-skeleton></p-skeleton>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </nz-card>
</div>
