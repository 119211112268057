import {Component, Input, OnInit} from '@angular/core';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {message} from '../../../../../environments/const.component';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {SolicitudesService} from '../../../../core/services/pasajes-viaticos/solicitudes.service';

@Component({
  selector: 'app-mod-registro-destinos',
  templateUrl: './mod-registro-destinos.component.html',
  styleUrls: ['./mod-registro-destinos.component.css']
})
export class ModRegistroDestinosComponent implements OnInit {
  public processing = false;
  public descDestino: any = null;
  @Input() title?: string;
  @Input() content?: any;
  @Input() edit?: boolean;
  @Input() transaccion?: string;

  constructor(private modal: NzModalRef,
              private cnf: NzModalService,
              private notification: NzNotificationService,
              private srv: SolicitudesService) {
  }

  ngOnInit(): void {
    if(this.edit){
      this.descDestino = this.content.descripcion;
      this.content.transaccion = this.transaccion;
      this.content.login = sessionStorage.getItem('login');
    }
  }

  public destroyModal(result: any = {}): void {
    this.modal.destroy(result);
  }

  public prcGestionDestinos(): void {
    this.cnf.confirm({
      nzTitle: '<i>Atención!</i>',
      nzContent: `¿Desea ${this.content.transaccion} el registro?`,
      nzOnOk: () => {
        this.content.descripcion =  this.descDestino;
        this.srv.srvGestionDestinos(this.content).subscribe(resp => {
            if (resp.correcto) {
              this.destroyModal(resp);
            } else {
              this.notification.create('error', 'Error', resp.notificacion);
            }
          },
          err => {
            // @ts-ignore
            this.notification.create('error', 'Error', message[err.status]);
          });
      }
    });
  }
}
