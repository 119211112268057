import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dlg-revertir',
  templateUrl: './dlg-revertir.component.html',
  styleUrls: ['./dlg-revertir.component.css']
})
export class DlgRevertirComponent implements OnInit {
  public title = null;
  public observacion = null;
  constructor(
    public dialogRef: MatDialogRef<DlgRevertirComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  ngOnInit(): void {
  }
  public onNoClick(refClose: any): void {
    this.dialogRef.close(refClose);
  }
}
