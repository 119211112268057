import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServiceExceptionService} from "../service-exception.service";
import {Observable, throwError} from "rxjs";
import {ResultInterface} from "../../models/auth/result-interface";
import {endPoint} from "../../../../environments/environment";
import {catchError, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ClasificadorService extends ServiceExceptionService  {

  constructor(private http: HttpClient) {
    super();
  }
  public lstClasificador(idclasificador: number): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/parametros/clasificador?idclasificador=${idclasificador}`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

}
