import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzNotificationService} from "ng-zorro-antd/notification";
import {merge, Observable, timer} from 'rxjs';
import {delay, finalize, map, scan} from 'rxjs/operators';
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {SyncStepInterface} from "../../../../core/models/steps/sync-step-interface";
import {AsyncStepInterface} from "../../../../core/models/steps/async-step-interface";
import {endOfMonth} from 'date-fns';

type Step = SyncStepInterface | AsyncStepInterface;
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment, Moment} from 'moment';
import {SolicitudesService} from "../../../../core/services/pasajes-viaticos/solicitudes.service";
import {ClasificadorService} from "../../../../core/services/parametros/clasificador.service";
import {UsuariosService} from "../../../../core/services/admin/usuarios.service";
import {message} from "../../../../../environments/const.component";

const moment = _rollupMoment || _moment;

function mockAsyncStep(): Observable<number> {
  const subStep1 = timer(600).pipe(map(() => 25));
  const subStep2 = subStep1.pipe(delay(600));
  const subStep3 = subStep2.pipe(delay(600));
  const subStep4 = subStep3.pipe(delay(600));
  return merge(subStep1, subStep2, subStep3, subStep4).pipe(scan((a, b) => a + b));
}

@Component({
  selector: 'app-modal-reg-solicitudes',
  templateUrl: './modal-reg-solicitudes.component.html',
  styleUrls: ['./modal-reg-solicitudes.component.css']
})
export class ModalRegSolicitudesComponent implements OnInit {
  public isConfirmLoading = false;
  public isTipoViajeExt = false;
  public fgDatosGrals: FormGroup | any;
  public fgPaso2: FormGroup | any;
  public fgPaso3: FormGroup | any;
  public fgPaso4: FormGroup | any;
  public optEstipendios: any[] = [];
  public optDestinos: any[] = [];
  public optDeptos: any[] = [];
  public optPersonal: any[] = [];
  public isFinSemanaFeriado = false;
  public isRenunciaViatico = false;
  public value = null;
  // public ranges = { Today: [new Date(), new Date()], 'This Month': [new Date(), endOfMonth(new Date())] };
  public formatterBol = (value: number): string => `Bs. ${value}`;
  public parserBol = (value: string): string => value.replace('Bs. ', '');
  public formatterSus = (value: number): string => `$us. ${value}`;
  public parserSus = (value: string): string => value.replace('$us. ', '');
  public formatterDias = (value: number): string => `${value} días`;
  public parserDias = (value: string): string => value.replace(' días', '')
  public steps: Step[] = [
    {
      id: 1,
      title: `Paso 1`,
      description: `Declaratoria en Comisión`,
      async: false,
      percentage: null
    },
    {
      id: 2,
      title: `Paso 2`,
      description: `Declaración/Autorización.`,
      async: true,
      percentage: 0
    },
    {
      id: 3,
      title: `Paso 3`,
      description: `Solicitud de Pasajes`,
      async: true,
      percentage: 0
    },
    {
      id: 4,
      title: `Paso 4`,
      description: `Liquidación de Viaticos`,
      async: true,
      percentage: 0
    }
  ];
  public current = 0;
  public processing = false;
  @Input() title?: string;
  @Input() content?: any;
  @Input() edit?: boolean;
  @Input() transaccion?: string;
  public usrSigec: any;
  public maxDias: number = 0.00;
  public isDisabled = true;
  public isExterno = false;
  public isDisabledRcIva = true;
  public isGastosRep = false;
  public minDate = new Date();
  public maxDate = new Date();
  public dflDay: Date = new Date();
  public isDescargo = false;

  constructor(private frmBuilder: FormBuilder,
              private modal: NzModalRef,
              private cnf: NzModalService,
              private notification: NzNotificationService,
              private srv: SolicitudesService,
              private clf: ClasificadorService,
              private usr: UsuariosService) {
  }

  ngOnInit(): void {
    this.fgDatosGrals = this.frmBuilder.group({
      idSolicitud: [null],
      solNumero: [null],
      destinoId: [null, [Validators.required]],
      solObjetivo: [null, [Validators.required]],
      solTipoCambio: [0, [Validators.required]],
      solEsFeriado: [null, [Validators.required]],
      solRmFinsemanaFeriado: [false],
      solRmReembolso: [false],
      solJustificacionFeriado: [null],
      estipendioId: [null, [Validators.required]],
      nombreCompleto: [{value: sessionStorage.getItem('nombreCompleto'), disabled: true}],
      cargo: [{value: sessionStorage.getItem('cargo'), disabled: true}],
      solUsuarioDeclara: [null],
      solUsuarioAutoriza: [null],
      solLugarDeclara: [null],
      solLugarAutoriza: [null],
      solFechaDeclara: [{value: moment(new Date(),'DD/MM/YYYYY').format()}],
      solFechaAutoriza: [{value: moment(new Date(),'DD/MM/YYYYY').format()}],
      idLiquidacion: [null],
      pvRutaAereo: [null],
      pvRutaTerrestre: [null],
      pvNumeroPasajes: [0],
      pvNumeroDias: [0],
      pvImporteAereo: [0],
      pvImporteTerrestre: [0],
      pvImporteTotal: [0],
      pvRenunciaViatico: [false],
      pvDiasRenunciaViatico: [null],
      pvFechaHoraSalidaRetorno: [null],
      pvFechaHoraPartida: [null],
      pvFechaHoraRetorno: [null],
      pvRcIva: [false],
      pvRcIvaImporte: [0],
      pv100Dias: [0],
      pv100Importe: [0],
      pv70Dias: [0],
      pv70Importe: [0],
      pv25Dias: [0],
      pv25Importe: [0],
      pvPasViaTotal: [0],
      pvGastosRepre: [false],
      pvGastosRepreImporte: [0],
      pvIva: [false],
      pvIvaImporte: [0],
      pvGastosRepreTotal: [0],
      pvRetencion: [0],
      pvGranTotal: [0],
      pvTvUsd: [0],
      pvTvBs: [0],
      pvTgrUsd: [0],
      pvTgrBs: [0],
      pvTpUsd: [0],
      pvTpBs: [0],
      pvTotalUsd: [0],
      pvTotalBs: [0],
      observacion: [null],
      transaccion: [this.transaccion],
      estado: ['INICIAL'],
      login: [sessionStorage.getItem('login')],
      citeSol: [null],
      nurSol: [null]
    });
    sessionStorage.getItem('ext') === 'true' ? this.fgDatosGrals.controls.pvRcIva.setValue(true) : this.fgDatosGrals.controls.pvRcIva.setValue(false);
    this.fgDatosGrals.controls.solEsFeriado.setValue(false);
    if (this.edit) {
      this.fgDatosGrals.patchValue(this.content);
      this.isFinSemanaFeriado = this.content.solEsFeriado;
      this.onchangeFinSemFeriado(this.content.solEsFeriado);
      this.isRenunciaViatico = this.content.pvRenunciaViatico;
      this.onchangeDiasRenunciaViatico(this.content.pvRenunciaViatico);
      if (this.content.pvRenunciaViatico){
        this.minDate = new Date(moment(this.content.pvFechaHoraPartida, 'DD/MM/YYYY HH:mm:ss').format());
        this.maxDate = new Date(moment(this.content.pvFechaHoraRetorno, 'DD/MM/YYYY HH:mm:ss').format());
      }else{
        this.minDate = new Date();
        this.maxDate = new Date();
      }
      this.fgDatosGrals.controls.solFechaDeclara.setValue(moment(this.content.solFechaDeclara, 'DD/MM/YYYY').format());
      this.fgDatosGrals.controls.solFechaAutoriza.setValue(moment(this.content.solFechaAutoriza, 'DD/MM/YYYY').format());
      this.fgDatosGrals.controls.pvFechaHoraSalidaRetorno.setValue([moment(this.content.pvFechaHoraPartida, 'DD/MM/YYYY HH:mm:ss').format(), moment(this.content.pvFechaHoraRetorno, 'DD/MM/YYYY HH:mm:ss').format()]);
      this.fgDatosGrals.controls.estado.setValue(this.content.estado);
      this.fgDatosGrals.controls.pv100Dias.setValue(this.content.pvCienDias);
      this.fgDatosGrals.controls.pv100Importe.setValue(this.content.pvCienImporte);
      this.fgDatosGrals.controls.pv70Dias.setValue(this.content.pvSetentaDias);
      this.fgDatosGrals.controls.pv70Importe.setValue(this.content.pvSetentaImporte);
      this.fgDatosGrals.controls.pv25Dias.setValue(this.content.pvVcincoDias);
      this.fgDatosGrals.controls.pv25Importe.setValue(this.content.pvVcincoImporte);
      this.fgDatosGrals.controls.transaccion.setValue(this.transaccion);
      if (this.content.pvRenunciaViatico) {
        const dias = (this.content.pvDiasRenunciaViatico).split(',');
        const arryDias: any = [];
        dias.forEach((value: any, index: number) => {
          arryDias.push(new Date(moment(value, 'YYYY-MM-DD').format()));
        });
        this.fgDatosGrals.controls.pvDiasRenunciaViatico.setValue(arryDias);
      }
      this.isDescargo = this.transaccion === 'DESCARGAR';
    }else{
      this.fgDatosGrals.controls.solFechaDeclara.setValue(moment(new Date(), 'DD/MM/YYYY').format());
      this.fgDatosGrals.controls.solFechaAutoriza.setValue(moment(new Date(), 'DD/MM/YYYY').format());
    }
    this.fgDatosGrals.updateValueAndValidity();
    this.prcOptionsEstipendios();
    this.prcOptionsDeptos();
    this.prcOptionsUsr();
    this.prcUsrSigec();
    sessionStorage.getItem('lvl') === '1' ?this.isGastosRep = true : this.isGastosRep = false;
    sessionStorage.getItem('ext') === 'true' ? this.isExterno = true : this.isExterno = false;
    this.onChangeRcIva(true);
  }

  pre(): void {
    this.current -= 1;
    switch (this.current) {
      case 0:
        this.fgDatosGrals.controls["solUsuarioAutoriza"].setValidators([]);
        this.fgDatosGrals.controls["solLugarAutoriza"].setValidators([]);
        this.fgDatosGrals.controls["solUsuarioDeclara"].setValidators([]);
        this.fgDatosGrals.controls["solLugarDeclara"].setValidators([]);
        // this.fgDatosGrals.controls["pvRutaAereo"].setValidators([]);
        this.fgDatosGrals.controls["pvNumeroPasajes"].setValidators([]);
        // this.fgDatosGrals.controls["pvRutaTerrestre"].setValidators([]);
        this.fgDatosGrals.controls["pvFechaHoraSalidaRetorno"].setValidators([]);
        this.fgDatosGrals.controls["pvNumeroDias"].setValidators([]);
        this.fgDatosGrals.controls["pvImporteAereo"].setValidators([]);
        this.fgDatosGrals.controls["pvImporteTerrestre"].setValidators([]);
        this.fgDatosGrals.controls["pvRenunciaViatico"].setValidators([]);
        this.fgDatosGrals.controls["pvDiasRenunciaViatico"].setValidators([]);
        break;
      case 1:
        // this.fgDatosGrals.controls["pvRutaAereo"].setValidators([]);
        this.fgDatosGrals.controls["pvNumeroPasajes"].setValidators([]);
        // this.fgDatosGrals.controls["pvRutaTerrestre"].setValidators([]);
        this.fgDatosGrals.controls["pvFechaHoraSalidaRetorno"].setValidators([]);
        this.fgDatosGrals.controls["pvNumeroDias"].setValidators([]);
        this.fgDatosGrals.controls["pvImporteAereo"].setValidators([]);
        this.fgDatosGrals.controls["pvImporteTerrestre"].setValidators([]);
        this.fgDatosGrals.controls["pvRenunciaViatico"].setValidators([]);
        this.fgDatosGrals.controls["pvDiasRenunciaViatico"].setValidators([]);
        break;
      case 2:
        this.fgDatosGrals.controls["pv100Dias"].setValidators([]);
        this.fgDatosGrals.controls["pv70Dias"].setValidators([]);
        this.fgDatosGrals.controls["pv25Dias"].setValidators([]);
        break;
    }
    this.fgDatosGrals.updateValueAndValidity();
  }

  next(): void {
    if (!this.fgDatosGrals.valid) {
      Object.values(this.fgDatosGrals.controls).forEach(control => {
        // @ts-ignore
        if (control.invalid) {
          // @ts-ignore
          control.markAsDirty();
          // @ts-ignore
          control.updateValueAndValidity({onlySelf: true});
        }
      });
      this.notification.create('warning', 'Error', 'Existen datos llenados incorrectamente.');
    }else {
      this.current += 1;

      switch (this.current) {
        case 1:
          this.fgDatosGrals.controls["solUsuarioAutoriza"].setValidators([Validators.required]);
          this.fgDatosGrals.controls["solLugarAutoriza"].setValidators([Validators.required]);
          this.fgDatosGrals.controls["solUsuarioDeclara"].setValidators([Validators.required]);
          this.fgDatosGrals.controls["solLugarDeclara"].setValidators([Validators.required]);
          break;
        case 2:
          // this.fgDatosGrals.controls["pvRutaAereo"].setValidators([Validators.required]);
          this.fgDatosGrals.controls["pvNumeroPasajes"].setValidators([Validators.required]);
          // this.fgDatosGrals.controls["pvRutaTerrestre"].setValidators([Validators.required]);
          this.fgDatosGrals.controls["pvFechaHoraSalidaRetorno"].setValidators([Validators.required]);
          this.fgDatosGrals.controls["pvNumeroDias"].setValidators([Validators.required]);
          this.fgDatosGrals.controls["pvImporteAereo"].setValidators([Validators.required]);
          this.fgDatosGrals.controls["pvImporteTerrestre"].setValidators([Validators.required]);
          this.fgDatosGrals.controls["pvRenunciaViatico"].setValidators([Validators.required]);
          if(this.fgDatosGrals.controls.pvRenunciaViatico.value){
            this.fgDatosGrals.controls["pvDiasRenunciaViatico"].setValidators([Validators.required]);
          }
          break;
        case 3:
          this.fgDatosGrals.controls["pv100Dias"].setValidators([Validators.required]);
          this.fgDatosGrals.controls["pv70Dias"].setValidators([Validators.required]);
          this.fgDatosGrals.controls["pv25Dias"].setValidators([Validators.required]);
          break;
      }
      this.fgDatosGrals.updateValueAndValidity();
    }
  }
  public onchangeFinSemFeriado(val: boolean): void{
    val? this.fgDatosGrals.controls["solJustificacionFeriado"].setValidators([Validators.required]) :
      this.fgDatosGrals.controls["solJustificacionFeriado"].setValidators([]);
    this.fgDatosGrals.controls["solJustificacionFeriado"].updateValueAndValidity();
  }
  public onchangeDiasRenunciaViatico(val: boolean): void{
    val? this.fgDatosGrals.controls["pvDiasRenunciaViatico"].setValidators([Validators.required]) :
      this.fgDatosGrals.controls["pvDiasRenunciaViatico"].setValidators([]);
    this.fgDatosGrals.controls["pvDiasRenunciaViatico"].updateValueAndValidity();

    if (this.isRenunciaViatico){
      this.minDate = new Date(moment(this.fgDatosGrals.controls.pvFechaHoraPartida.value, 'DD/MM/YYYY HH:mm:ss').format());
      this.maxDate = new Date(moment(this.fgDatosGrals.controls.pvFechaHoraRetorno.value, 'DD/MM/YYYY HH:mm:ss').format());
    }else{
      this.minDate = new Date();
      this.maxDate = new Date();
    }
    this.dflDay = this.minDate;
  }

  public destroyModal(result: any = {}): void {
    this.modal.destroy({data: result});
  }

  public prcOptionsEstipendios(): void {
    this.srv.srvEstipendios({login: sessionStorage.getItem('login')}).subscribe(res => {
        if (res.correcto) {
          this.optEstipendios = res.datoAdicional;
          if (this.edit) {
            this.fgDatosGrals.controls.estipendioId.setValue(this.content.estipendioId);
            this.prcOptDestinosNivel(this.content.estipendioId);
          }
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', 'err');
      });
  }

  public prcOptionsDeptos(): void {
    this.clf.lstClasificador(244).subscribe(res => {
        if (res.correcto) {
          this.optDeptos = res.datoAdicional;
          if (this.edit) {
            this.fgDatosGrals.controls.solLugarAutoriza.setValue(this.content.solLugarAutoriza);
            this.fgDatosGrals.controls.solLugarDeclara.setValue(this.content.solLugarDeclara);
          }
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', 'err');
      });
  }

  public prcOptionsUsr(): void {
    this.usr.getPersonalVigente().subscribe(res => {
        if (res.correcto) {
          this.optPersonal = res.datoAdicional;
          // if (this.data.edit) {
          //   this.fgDatosGrals.controls.idClasificadorTramite.setValue(this.data.res.idClasificadorTramite);
          // }
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', 'err');
      });
  }

  public prcOptDestinosNivel(value: number): void {
    const vEstiNivel = this.optEstipendios.filter(x => x.idEstipendio === value);
    this.fgDatosGrals.controls.destinoId.setValue(null);
    if (value < 7) {
      this.isTipoViajeExt = true;
    } else {
      this.isTipoViajeExt = false;
      this.fgDatosGrals.controls.pvGastosRepre.setValue(false);
      this.fgDatosGrals.controls.pvGastosRepreImporte.setValue(0);
      this.fgDatosGrals.controls.pvIva.setValue(false);
      this.fgDatosGrals.controls.pvIvaImporte.setValue(0);
      this.fgDatosGrals.controls.pvGastosRepreTotal.setValue(0);
      this.fgDatosGrals.controls.pvRetencion.setValue(0);
      this.fgDatosGrals.controls.pvGranTotal.setValue(0);
      this.fgDatosGrals.controls.pvTvUsd.setValue(0);
      this.fgDatosGrals.controls.pvTvBs.setValue(0);
      this.fgDatosGrals.controls.pvTgrUsd.setValue(0);
      this.fgDatosGrals.controls.pvTgrBs.setValue(0);
      this.fgDatosGrals.controls.pvTpUsd.setValue(0);
      this.fgDatosGrals.controls.pvTpBs.setValue(0);
      this.fgDatosGrals.controls.pvTotalUsd.setValue(0);
      this.fgDatosGrals.controls.pvTotalBs.setValue(0);
    }
    this.srv.srvDestinosNivel({estiNivel: vEstiNivel[0].estNivel}).subscribe(res => {
        if (res.correcto) {
          this.optDestinos = res.datoAdicional;
          if (this.edit) {
            this.fgDatosGrals.controls.destinoId.setValue(this.content.destinoId);
          }
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', 'err');
      });
    if(vEstiNivel[0].estMoneda != 'USD')
    this.fgDatosGrals.controls.solTipoCambio.setValue(0);
  }
  public prcCalculoDias(val1: string, val2: string, val3: any): void {
    const prm = {
      fecIni: val1,
      fecFin: val2,
      diasRen: val3
    };
    this.srv.srvCalculoDias(prm).subscribe(res => {
        if (res.correcto) {
          this.fgDatosGrals.controls.pvNumeroDias.setValue(res.datoAdicional[0].totalDias);
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', 'err');
      });
  }

  public prcGestionSolicitudesPsjVts(): void {
    if (!this.fgDatosGrals.valid) {
      Object.values(this.fgDatosGrals.controls).forEach(control => {
        // @ts-ignore
        if (control.invalid) {
          // @ts-ignore
          control.markAsDirty();
          // @ts-ignore
          control.updateValueAndValidity({onlySelf: true});
        }
      });
      this.notification.create('error', 'Error', 'Existen datos no llenados correctamente.');
    }else {
      let nroDias = 0;
      nroDias = this.fgDatosGrals.controls.pv70Dias.value + this.fgDatosGrals.controls.pv25Dias.value + this.fgDatosGrals.controls.pv100Dias.value;
      if (this.fgDatosGrals.controls.pvNumeroDias.value === nroDias) {
        this.cnf.confirm({
          nzTitle: '<i>Atención!</i>',
          nzContent: '<b>Esta segura(o) de realizar el registro?</b>',
          nzOnOk: () => {
            this.isDescargo = false;
            this.isDisabled = false;
            this.isDisabledRcIva = false;
            const prm = {
              "usuario": sessionStorage.getItem('login'),
              "idVia": 0,
              "idDestinatario": 755,
              "idTipoDocumento": 3,
              "referencia": "Solicitud de Viaje",
              "fojas": 0,
              "hojaRuta": true,
              "proveido": "Para su atención."
            }
            if (this.transaccion === 'ADICIONAR') {
              this.srv.srvGeneraCiteSigec(prm).subscribe(res => {
                  if (res.correcto) {
                    this.fgDatosGrals.controls.citeSol.setValue(res.datoAdicional.cite);
                    this.fgDatosGrals.controls.nurSol.setValue(res.datoAdicional.hojaRuta);
                    this.srv.srvGestionSolicitudesPsjViaticos(this.fgDatosGrals.value).subscribe(res => {
                        if (res.correcto) {
                          this.destroyModal(res);
                        } else {
                          this.notification.create('error', 'Error', res.notificacion);
                          this.isDescargo = this.transaccion === 'DESCARGAR';
                        }
                      },
                      err => {
                        // @ts-ignore
                        this.notification.create('error', 'Error', message[err.status]);
                        this.isDescargo = this.transaccion === 'DESCARGAR';
                      });
                    this.isDisabled = true;
                    this.isDisabledRcIva = true;
                  } else {
                    this.notification.create('error', 'Error', res.notificacion);
                    this.isDescargo = this.transaccion === 'DESCARGAR';
                  }
                },
                err => {
                  // @ts-ignore
                  this.notification.create('error', 'Error', message[err.status]);
                  this.isDescargo = this.transaccion === 'DESCARGAR';
                });
            } else {
              this.srv.srvGestionSolicitudesPsjViaticos(this.fgDatosGrals.value).subscribe(res => {
                  if (res.correcto) {
                    this.destroyModal(res);
                  } else {
                    this.notification.create('error', 'Error', res.notificacion);
                    this.isDescargo = this.transaccion === 'DESCARGAR';
                  }
                },
                err => {
                  // @ts-ignore
                  this.notification.create('error', 'Error', message[err.status]);
                  this.isDescargo = this.transaccion === 'DESCARGAR';
                });
              this.isDisabled = true;
              this.isDisabledRcIva = true;
            }
          }
        });
      } else {
        this.notification.create('error', 'Error', 'Los días de viático SON INFERIORES a los días asignados.');
      }
    }
  }

  public onChange(result: Date[]): void {
    this.fgDatosGrals.controls.pvFechaHoraPartida.setValue(moment(result[0]).format('DD/MM/YYYY HH:mm:ss'));
    this.fgDatosGrals.controls.pvFechaHoraRetorno.setValue(moment(result[1]).format('DD/MM/YYYY HH:mm:ss'));
    this.minDate = result.length > 1 ? result[0] : new Date();
    this.maxDate = result.length > 1 ? result[1] : new Date();
    this.dflDay = this.minDate;
    const diasRen: string = ((JSON.stringify(this.fgDatosGrals.controls.pvDiasRenunciaViatico.value)).replace('[', '{')).replace(']', '}');
    this.prcCalculoDias(moment(result[0]).format('DD/MM/YYYY HH:mm'), moment(result[1]).format('DD/MM/YYYY HH:mm'), this.fgDatosGrals.controls.pvDiasRenunciaViatico.value);
    this.onChangeRcIva(this.fgDatosGrals.controls.pvRcIva.value);
  }

  public onChangeTotalPv(val: any): void {
    this.fgDatosGrals.controls.pvImporteTotal.setValue(this.fgDatosGrals.controls.pvImporteAereo.value + this.fgDatosGrals.controls.pvImporteTerrestre.value);
  }

  public onChangeViaticos(val: any, ptje: number): void {
    const estipendio = ((this.optEstipendios.filter((x: any) => x.idEstipendio === this.fgDatosGrals.controls.estipendioId.value))[0]).estCosto;
    const moneda = (this.optEstipendios.filter(x => x.idEstipendio === this.fgDatosGrals.controls.estipendioId.value))[0].estMoneda;
    let importe = 0;
    switch (ptje) {
      case 100:
        moneda === 'USD' ? importe = (((estipendio * ptje) / 100) * val) * (this.fgDatosGrals.controls.solTipoCambio.value) : importe = ((estipendio * ptje) / 100) * val;
        this.fgDatosGrals.controls.pv100Importe.setValue(importe);
        this.maxDias = this.fgDatosGrals.controls.pvNumeroDias.value - (this.fgDatosGrals.controls.pv70Dias.value + this.fgDatosGrals.controls.pv25Dias.value);
        // if (this.fgDatosGrals.controls.pvRenunciaViatico.value) {
        //   this.maxDias = this.maxDias - (this.fgDatosGrals.controls.pvDiasRenunciaViatico.value).length;
        // }
        break;
      case 70:
        moneda === 'USD' ? importe = (((estipendio * ptje) / 100) * val) * (this.fgDatosGrals.controls.solTipoCambio.value) : importe = ((estipendio * ptje) / 100) * val;
        this.fgDatosGrals.controls.pv70Importe.setValue(importe);
        this.maxDias = this.fgDatosGrals.controls.pvNumeroDias.value - (this.fgDatosGrals.controls.pv100Dias.value + this.fgDatosGrals.controls.pv25Dias.value);
        // if (this.fgDatosGrals.controls.pvRenunciaViatico.value) {
        //   this.maxDias = this.maxDias - (this.fgDatosGrals.controls.pvDiasRenunciaViatico.value).length;
        // }
        break;
      case 25:
        moneda === 'USD' ? importe = (((estipendio * ptje) / 100) * val) * (this.fgDatosGrals.controls.solTipoCambio.value) : importe = ((estipendio * ptje) / 100) * val;
        this.fgDatosGrals.controls.pv25Importe.setValue(importe);
        this.maxDias = this.fgDatosGrals.controls.pvNumeroDias.value - (this.fgDatosGrals.controls.pv100Dias.value + this.fgDatosGrals.controls.pv70Dias.value);
        // if (this.fgDatosGrals.controls.pvRenunciaViatico.value) {
        //   this.maxDias = this.maxDias - (this.fgDatosGrals.controls.pvDiasRenunciaViatico.value).length;
        // }
        break;
    }
    // if (this.fgDatosGrals.controls.pvRcIva.value) {
      this.onChangeRcIva(this.fgDatosGrals.controls.pvRcIva.value);
    // }else{
    //   this.fgDatosGrals.controls.pvRcIvaImporte.setValue(0);
    // }
    this.calculoGastosRepresentacion();
    this.fgDatosGrals.controls.pvGastosRepre.setValue(true);
    this.onChangeGstRpr(true);
  }
  public onChangeTotalImpVco(val: any): void {
    this.fgDatosGrals.controls.pvPasViaTotal.setValue((this.fgDatosGrals.controls.pv100Importe.value + this.fgDatosGrals.controls.pv70Importe.value + this.fgDatosGrals.controls.pv25Importe.value) - this.fgDatosGrals.controls.pvRcIvaImporte.value);
  }
  public onChangeDiasViaje(val: any): void {
    this.maxDias = val;
    this.fgDatosGrals.controls.pv100Dias.setValue(this.maxDias);
    this.onChangeViaticos(this.maxDias, 100);
    this.fgDatosGrals.controls.pv70Dias.setValue(0);
    this.fgDatosGrals.controls.pv25Dias.setValue(0);
  }

  public onChangeGstRpr(val: any): void {
    if (val) {
      const cst = (this.fgDatosGrals.controls.pvPasViaTotal.value * 25)/100 ;
      this.fgDatosGrals.controls.pvGastosRepreImporte.setValue(cst);
    } else {
      this.fgDatosGrals.controls.pvGastosRepreImporte.setValue(0);
    }
    this.calculoGastosRepresentacion();
  }
  public onChangeIvaGstRpr(val: any): void {
    if (val) {
      const cst = (this.fgDatosGrals.controls.pvPasViaTotal.value * 13)/100 ;
      this.fgDatosGrals.controls.pvIvaImporte.setValue(cst);
      this.fgDatosGrals.controls.pvRetencion.setValue(cst);
    } else {
      this.fgDatosGrals.controls.pvIvaImporte.setValue(0);
      this.fgDatosGrals.controls.pvRetencion.setValue(0);
    }
    this.calculoGastosRepresentacion();
  }

  public calculoGastosRepresentacion():void{
    let totalVtcGstRpr = 0;
    let totalGstRpr = 0;
    const estipendio = ((this.optEstipendios.filter((x: any) => x.idEstipendio === this.fgDatosGrals.controls.estipendioId.value))[0]).estCosto;

    totalGstRpr = this.fgDatosGrals.controls.pvGastosRepreImporte.value - this.fgDatosGrals.controls.pvIvaImporte.value;
    totalVtcGstRpr = this.fgDatosGrals.controls.pvPasViaTotal.value + totalGstRpr;
    this.fgDatosGrals.controls.pvGastosRepreTotal.setValue(totalGstRpr);
    this.fgDatosGrals.controls.pvGranTotal.setValue(totalVtcGstRpr);

    this.fgDatosGrals.controls.pvTvUsd.setValue(estipendio * this.fgDatosGrals.controls.pvNumeroDias.value);
    this.fgDatosGrals.controls.pvTvBs.setValue(this.fgDatosGrals.controls.pvPasViaTotal.value);
    this.fgDatosGrals.controls.pvTgrUsd.setValue(((this.fgDatosGrals.controls.pvGastosRepreImporte.value/this.fgDatosGrals.controls.solTipoCambio.value) * this.fgDatosGrals.controls.pvNumeroDias.value *25)/100);
    this.fgDatosGrals.controls.pvTgrBs.setValue(this.fgDatosGrals.controls.pvGastosRepreImporte.value);
    this.fgDatosGrals.controls.pvTpUsd.setValue(this.fgDatosGrals.controls.pvImporteTotal.value/this.fgDatosGrals.controls.solTipoCambio.value);
    this.fgDatosGrals.controls.pvTpBs.setValue(this.fgDatosGrals.controls.pvImporteTotal.value);
    this.fgDatosGrals.controls.pvTotalUsd.setValue(this.fgDatosGrals.controls.pvTvUsd.value + this.fgDatosGrals.controls.pvTgrUsd.value + this.fgDatosGrals.controls.pvTpUsd.value);
    this.fgDatosGrals.controls.pvTotalBs.setValue(this.fgDatosGrals.controls.pvTvBs.value + this.fgDatosGrals.controls.pvTgrBs.value + this.fgDatosGrals.controls.pvTpBs.value);
  }

  public onChangeRcIva(val: any): void {
    if (val) {
      const totalVco = this.fgDatosGrals.controls.pv100Importe.value + this.fgDatosGrals.controls.pv70Importe.value + this.fgDatosGrals.controls.pv25Importe.value;
      this.fgDatosGrals.controls.pvRcIvaImporte.setValue(totalVco * 0.13);
    } else {
      this.fgDatosGrals.controls.pvRcIvaImporte.setValue(0);
    }
    this.onChangeTotalImpVco(0);
  }


  public prcUsrSigec(): void {
    this.usr.getUsuariosSigec().subscribe(res => {
        if (res.correcto) {
          this.usrSigec = res.datoAdicional;
          sessionStorage.setItem('usrsigec', (this.usrSigec.filter((x: any) => x.username === sessionStorage.getItem('login')))[0].id);
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        // @ts-ignore
        this.notification.create('error', 'Error', 'err');
      });
  }

  loadingAndStep(): void {
    if (this.current < this.steps.length) {
      const step = this.steps[this.current];
      if (step.async) {
        this.processing = true;
        mockAsyncStep()
          .pipe(
            finalize(() => {
              step.percentage = 0;
              this.processing = false;
              this.current += 1;
            })
          )
          .subscribe(p => {
            step.percentage = p;
          });
      } else {
        this.current += 1;
      }
    }
  }
}
