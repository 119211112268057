import {HttpHeaders} from '@angular/common/http';

export const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
export const headerFormData = { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) };
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMM YYYY',
  },
};
export const FORMATS_MMYYYY = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const message = {
  '404': 'El servicio solicitado no se encuentra disponible',
  '508': 'El servicio no responde',
  '503': 'El servicio no esta disponible en estos momentos',
  '505': 'El servicio del SIN no esta disponible en estos momentos',
  '502': 'Bad Gatenway',
  '504': 'El tiempo para la respuesta a expirado',
  '501': 'No implementado',
  '200': 'Transacción realizada exitosamente!',
  '400': 'Peticion incorrecta',
  '401': 'No autorizado, se requiere autorización',
  '403': 'No se tiene los permisos necesarios',
  '500': 'Error interno en el servidor',
  '510': 'El servicio de IMPUESTO NACIONALES no esta disponible en estos momentos, intente ingresar nuevamente mas tarde. En caso de persistir el inconveniente comuniquese a las líneas de soporte. Gracias!',
};
export const apiext = {
  endpoint_cpt: 'https://ppte.agetic.gob.bo/busa/codigosPagoTramites',
  token_ppte: 'eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBR0VUSUMiLCJpYXQiOjE2NTUxMzAzNzIsImlkVXN1YXJpb0FwbGljYWNpb24iOjExLCJpZFRyYW1pdGUiOiI4MTgifQ.aBirfy4boFSE4HL6k9ADwkjNjw5KmFPw_tHT_18MGaA'
};
export const ctas = {
  psst: "10000006036425"
}
