import { Component, OnInit } from '@angular/core';
import {TransaccionesInterface} from "../../../core/models/transacciones/transacciones-interface";
import {UsuariosService} from "../../../core/services/admin/usuarios.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {DlgActivarUsuarioComponent} from "./dlg-activar-usuario/dlg-activar-usuario.component";
import {DlgNuevoUsuarioComponent} from "./dlg-nuevo-usuario/dlg-nuevo-usuario.component";
import {MatDialog} from "@angular/material/dialog";
import {message} from "../../../../environments/const.component";

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  public lstUsuarios: any[] = [];
  public selectedRow: any;
  public lstTransacciones: TransaccionesInterface[] | any;
  public loading: boolean = true;
  constructor(private srvUsr: UsuariosService,
              private notification: NzNotificationService,
              public modal: MatDialog) { }

  ngOnInit(): void {
    this.pListarUsuarios();
  }
  public operacion(op: string, param: string): void {
    // @ts-ignore
    this[op](param);
  }
  public onRowUnselect(event: any = null): void {
    const data = {
      estado: 'INICIAL',
      username: sessionStorage.getItem('login'),
      tabla: '6'
    };
    // this.loadLstTransacciones(data);
  }

  public onRowSelect(event: any): void {
    const data: TransaccionesInterface = {
      estado: event.data.estado,
      username: sessionStorage.getItem('login'),
      tabla: '6'
    };
    // this.loadLstTransacciones(data);
  }

  public pHabilitaUsr(user: any): void {
    const pData = {title: 'HABILITAR USUARIO', res: user};
    const cnf = {panelClass: 'dlgModal', disableClose: true, data: pData }
    const pModal = this.modal.open(DlgActivarUsuarioComponent, cnf);
    pModal.afterClosed().subscribe(res => {
      if (res.correcto) {
        this.pListarUsuarios();
        this.notification.create('success', 'Correcto', res.notificacion);
      }
    });
  }

  public gestionWorkflow(pUsr: any): void {
    const pData = {
      estado: 'HABILITADO',
      transaccion: 'DESHABILITAR',
      login: pUsr.usuario,
      correo: pUsr.correo,
      usuario: sessionStorage.getItem("login")
    };
    this.srvUsr.admUsuariosPsst(pData).subscribe(res => {
        if (res.correcto) {
          this.pListarUsuarios();
          this.notification.create('success', 'Correcto', res.notificacion);
        } else {
          //this.loading = false;
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        //this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }

  public emailActivarCuenta(pUsr: any): void {
    const pData = [{
      "email": "mcalzada@mintrabajo.gob.bo",//pUsr.correo,
      "usuario": pUsr.usuario,
      "usr": true
    }]
    this.srvUsr.emailActCta(pData).subscribe(res => {
        if (res.correcto) {
          this.pListarUsuarios();
          this.notification.create('success', 'Correcto', res.notificacion);
        } else {
          //this.loading = false;
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        //this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }

  public pAdicionarUsr(prm: string): void {
    const pData = {title: 'NUEVO USUARIO', res: this.selectedRow};
    const cnf = {panelClass: 'dlgModal', disableClose: true, data: pData }
    const pModal = this.modal.open(DlgActivarUsuarioComponent, cnf);
    pModal.afterClosed().subscribe(res => {
      if (res.correcto) {
        this.notification.create('success', 'Correcto', res.notificacion);
      } else {
        // this.notification.create('error', 'Error', res.notificacion);
      }
    });
  }

  public pListarUsuarios(): void {
    // @ts-ignore
    this.srvUsr.lstUsuarios().subscribe((res => {
      this.loading = false;
      if (res.correcto) {
        this.lstUsuarios = res.datoAdicional;
      } else {
        this.notification.create('error', 'Error', res.notificacion);
      }
    }));
  }
}
