import { Component, OnInit } from '@angular/core';
import {TransaccionesInterface} from "../../../core/models/transacciones/transacciones-interface";
import {DocumentosService} from "../../../core/services/parametros/documentos.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {MatDialog} from "@angular/material/dialog";
import {DlgRegistrarDocumentoComponent} from "../parametros/dlg-registrar-documento/dlg-registrar-documento.component";
import {message} from "../../../../environments/const.component";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {UsuariosService} from "../../../core/services/admin/usuarios.service";
import {DlgRegistrarRolComponent} from "./dlg-registrar-rol/dlg-registrar-rol.component";

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  public lstRoles: any[] = [];
  public selectedRow: any;
  public lstTransacciones: TransaccionesInterface[] | any;
  public loading: boolean = true;
  constructor(private srvDoc: DocumentosService,
              private notification: NzNotificationService,
              public modal: MatDialog,
              private srvUsr: UsuariosService) { }

  ngOnInit(): void {
    this.pListarRoles();
  }
  public operacion(op: string, param: string): void {
    // @ts-ignore
    this[op](param);
  }
  public onRowUnselect(event: any = null): void {
    const data = {
      estado: 'INICIAL',
      username: sessionStorage.getItem('login'),
      tabla: '6'
    };
    // this.loadLstTransacciones(data);
  }

  public onRowSelect(event: any): void {
    const data: TransaccionesInterface = {
      estado: event.data.estado,
      username: sessionStorage.getItem('login'),
      tabla: '6'
    };
    // this.loadLstTransacciones(data);
  }

  public pRegistrarRol(prm: string, row: any = null): void {
    const pData = {title: prm == 'ADD_ROL'? 'NUEVO ROL': 'EDITAR ROL', res: row, trns: prm};
    const cnf = {panelClass: 'dlgModal', disableClose: true, width: '400px', data: pData }
    const pModal = this.modal.open(DlgRegistrarRolComponent, cnf);
    pModal.afterClosed().subscribe(res => {
      if (res.correcto) {
        this.pListarRoles();
        this.notification.create('success', 'Correcto', res.notificacion);
      }
    });
  }

  public pListarRoles(): void {
    this.srvUsr.lstRoles('PSST', '%').subscribe((res => {
        this.loading = false;
        if (res.correcto) {
          this.lstRoles = res.datoAdicional;
        } else {
          this.notification.create('error', 'Error', res.notificacion);
        }
      }
    ));
  }

  public gestionDocumento(row: any): void {
    const pData = {
      rol: row.rol,
      estado: 'INICIAL',
      transaccion: 'ELIMINAR_ROL',
      login: sessionStorage.getItem("login")
    };
    this.srvDoc.gestionDocumentos(pData).subscribe(res => {
        if (res.correcto) {
          this.notification.create('success', 'Correcto', res.notificacion);
          this.pListarRoles();
        } else {
          //this.loading = false;
          this.notification.create('error', 'Error', res.notificacion);
        }
      },
      err => {
        //this.loading = false;
        // @ts-ignore
        this.notification.create('error', 'Error', message[err.status]);
      });
  }

}
