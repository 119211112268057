import { Injectable } from '@angular/core';
import {ServiceExceptionService} from "../service-exception.service";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {ResultInterface} from "../../models/auth/result-interface";
import {endPoint} from "../../../../environments/environment";
import {httpOptions} from "../../../../environments/const.component";
import {catchError, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ImpuestosService extends ServiceExceptionService {

  constructor(private http: HttpClient) {
    super();
  }
  public wsStatusSin(): Observable<ResultInterface> {
    return this.http.get<ResultInterface>(`${endPoint.cnx.url}/sin/status`).pipe(
      tap(msg => {
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
